import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as RouterSelectors from './router.selectors';

@Injectable()
export class RouterFacade {
  currentRoute$ = this.store.pipe(
    select(RouterSelectors.selectCurrentRoute) as any
  );
  fragment$ = this.store.pipe(select(RouterSelectors.selectFragment) as any);
  queryParams$ = this.store.pipe(
    select(RouterSelectors.selectQueryParams) as any
  );
  routeParams$ = this.store.pipe(
    select(RouterSelectors.selectCurrentRoute) as any
  );
  routeData$ = this.store.pipe(select(RouterSelectors.selectRouteData) as any);
  url$ = this.store.pipe(select(RouterSelectors.selectUrl) as any);

  queryParam$ = (param: string) =>
    this.store.pipe(select(RouterSelectors.selectQueryParam(param)) as any);
  routeParam$ = (param: string) =>
    this.store.pipe(select(RouterSelectors.selectRouteParam(param)) as any);

  constructor(private store: Store) {}
}
