<div fxFlexFill fxLayoutAlign="center center">
  <button
    *ngIf="params.data"
    mat-icon-button
    [color]="'accent'"
    (click)="click()"
  >
    <mat-icon>info_outline</mat-icon>
  </button>
  <button
    *ngIf="!params.data && false"
    mat-icon-button
    [color]="'accent'"
    (click)="click()"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
