import { ColDef } from '@ag-grid-enterprise/all-modules';

export const avatarColumn: ColDef = {
  cellEditor: 'filesEditor',
  cellRenderer: 'avatarRenderer',
  cellStyle: { padding: 0 },
  field: 'avatar',
  filter: false,
  headerName: '',
  initialWidth: 64,
  maxWidth: 64,
  minWidth: 64,
  resizable: false,
  sortable: false,
  suppressMenu: true,
  width: 64,
};
