import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { GridComponent } from '../../grid.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as pluralize from 'pluralize';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from '../../../../http/http.service';

@Component({
  selector: 'up-reference-renderer',
  templateUrl: './reference-renderer.component.html',
  styleUrls: ['./reference-renderer.component.scss'],
})
export class ReferenceRendererComponent implements AgRendererComponent {
  private params!: ICellRendererParams;
  private cellRendererParams!: { collection?: string; displayAs?: string };
  private field!: string;

  public values$!: Observable<any>;
  public displayAs!: string;

  constructor(private http: HttpService<any>, private snackBar: MatSnackBar) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.field = params.colDef?.field || '';
    this.cellRendererParams = params.colDef?.cellRendererParams;
    this.displayAs = params.colDef?.cellRendererParams?.displayAs || 'name';
    this.values$ = this.serialize(params.value);
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  serialize(values: unknown[]): Observable<any> {
    const collection =
      this.cellRendererParams?.collection || pluralize(this.field);
    if (!values || !collection) return of([]);

    values = Array.isArray(values) ? values : values ? [values] : [];
    return this.http
      .aggregate({
        collection,
        body: [
          { $match: { _id: { $in: values } } },
          { $project: { [this.displayAs]: 1 } },
        ],
      })
      .pipe(
        map((docs) =>
          values.map((value) => docs.filter((doc) => doc._id === value)[0])
        )
      );
  }

  click(): void {
    const parent: GridComponent = this.params.context.componentParent;
    parent.emitAction({ action: 'AVATAR', data: {} });
  }

  copied(id: string): void {
    this.snackBar.open(`成功複製 "${id}" 到剪貼簿`, '', { duration: 5000 });
  }
}
