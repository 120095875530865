import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HttpService } from '../../http/http.service';

@Component({
  selector: 'up-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss'],
})
export class LogsDialogComponent {
  logs$: Observable<any[]> = this.http.find.by({
    collection: `${this.data.collection}Logs`,
    queryParams: {
      match: { documentKey: this.data.documentKey },
      sort: { createdAt: -1 },
      lookups: [
        {
          from: 'users',
          localField: 'createdBy',
          foreignField: '_id',
          as: 'createdBy',
        },
      ],
    },
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { collection: string; documentKey: string },
    private http: HttpService<any>
  ) {}

  updateDescription(updateDescription: string): Record<string, unknown> {
    return JSON.parse(updateDescription);
  }

  $set(updateDescription: string): any {
    const output = JSON.parse(updateDescription).$set;
    delete output.updatedAt;
    delete output.updatedBy;
    return output;
  }
  $inc(updateDescription: string): any {
    const output = JSON.parse(updateDescription).$inc;
    return output;
  }

  oldValue(state: Record<string, unknown>, key: any): any {
    return state && state[key];
  }
}
