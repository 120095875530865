import { NavigationsEntity } from './+state/navigations.models';

const home: NavigationsEntity[] = [
  {
    id: 'home',
    icon: 'home',
    name: '首頁',
    description: '返回首頁概覽',
    router: { routerLink: ['./'] },
    children: [
      {
        id: 'home-dashboard',
        icon: 'dashboard',
        name: '概覽',
        router: { routerLink: ['./'] },
      },
      {
        id: 'home-cpm',
        icon: 'contacts',
        name: '通訊錄管理',
        router: { routerLink: ['./cpm'] },
      },
      {
        id: 'home-iam',
        icon: 'fingerprint',
        name: '身份及權限管理',
        router: { routerLink: ['./iam'] },
      },
      {
        id: 'home-settings',
        icon: 'settings',
        name: '設置',
        router: { routerLink: ['./settings'] },
      },
    ],
  },
];

const cpm: NavigationsEntity[] = [
  {
    id: 'cpm',
    icon: 'contacts',
    name: '通訊錄管理',
    description: '概覽、個人檔案',
    router: { routerLink: ['./iam'] },
    children: [
      {
        id: 'cpm-dashboard',
        icon: 'dashboard',
        name: '概覽',
        router: { routerLink: ['./cpm'] },
      },
      {
        id: 'cpm-profiles',
        icon: 'group',
        name: '個人檔案',
        router: { routerLink: ['./cpm/profiles'] },
      },
      {
        id: 'cpm-settings',
        icon: 'settings',
        name: '設置',
        router: { routerLink: ['./cpm/settings'] },
      },
    ],
  },
];

const iam: NavigationsEntity[] = [
  {
    id: 'iam',
    icon: 'fingerprint',
    name: '身份及權限管理',
    description: '概覽、用戶、角色、政策、資源、設置',
    router: { routerLink: ['./iam'] },
    children: [
      {
        id: 'iam-dashboard',
        icon: 'dashboard',
        name: '概覽',
        router: { routerLink: ['./iam'] },
      },
      {
        id: 'iam-users',
        icon: 'person',
        name: '用戶管理',
        router: { routerLink: ['./iam/users'] },
      },
      {
        id: 'iam-roles',
        icon: 'group',
        name: '角色管理',
        router: { routerLink: ['./iam/roles'] },
      },
      {
        id: 'iam-polcies',
        icon: 'policy',
        name: '政策管理',
        router: { routerLink: ['./iam/policies'] },
      },
      {
        id: 'iam-resources',
        icon: 'memory',
        name: '資源管理',
        router: { routerLink: ['./iam/resources'] },
      },
      {
        id: 'iam-settings',
        icon: 'settings',
        name: '設置',
        router: { routerLink: ['./iam/settings'] },
      },
    ],
  },
];

export const navigations: NavigationsEntity = {
  id: 'default',
  name: 'Default Navigations',
  children: [...home, ...cpm, ...iam],
};
