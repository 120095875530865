import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogsDialogComponent } from './logs-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LogsDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,

    // MATERIAL
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class LogsDialogModule {}
