import {
  ColDef,
  ValueFormatterParams,
  ValueSetterParams,
} from '@ag-grid-enterprise/all-modules';

const booleanCellClassRules = {
  'fg-green-500': (params: any) => params.value,
  'fg-red-500': (params: any) => !params.value,
};
const booleanValueFormatter = (params: ValueFormatterParams) =>
  params.value === true ? '✔' : params.value === false ? '✖' : '';
const booleanValueSetter = (params: ValueSetterParams) => {
  const field = params.colDef.field || '';
  if (params.newValue === 'true' || params.newValue === true)
    params.data[field] = true;
  else if (params.newValue === 'false' || params.newValue === false)
    params.data[field] = false;
  else params.data[field] = null;
  return true;
};

const filterParams = { filters: [{ filter: 'agSetColumnFilter' }] };

export const booleanColumn: ColDef = {
  cellClassRules: booleanCellClassRules,
  cellEditor: 'booleanEditor',
  filterParams,
  initialWidth: 128,
  valueFormatter: booleanValueFormatter,
  valueSetter: booleanValueSetter,
  width: 128,
};
