import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { GridComponent } from '../../grid.component';

@Component({
  selector: 'up-info-button-renderer',
  templateUrl: './info-button-renderer.component.html',
  styleUrls: ['./info-button-renderer.component.scss'],
})
export class InfoButtonRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  click(): void {
    const parent: GridComponent = this.params.context.componentParent;
    parent.emitAction({ action: 'INFO', data: {} });
  }
}
