<div fxFlexFill fxLayoutAlign="start center">
  <button
    *ngFor="let value of values$ | async"
    class="bg-primary-50 fg-primary-700"
    fxLayout="row"
    fxLayoutAlign="center center"
    mat-raised-button
  >
    <mat-icon
      (click)="$event.stopPropagation(); copied(value?._id)"
      [cdkCopyToClipboard]="value?._id"
      class="fg-grey-500"
    >
      content_copy
    </mat-icon>
    <span>
      {{ value ? (value[displayAs] ? value[displayAs] : value['_id']) : null }}
    </span>
  </button>
</div>
