import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-enterprise/all-modules';
import * as moment from 'moment-timezone';
import { IFilterDef } from '@ag-grid-enterprise/all-modules';
import * as _ from 'lodash';

const dateValueGetter = (params: ValueGetterParams) => {
  if (!params.data) return;
  if (!_.get(params.data, params.colDef.field || 'createdAt')) return;

  return moment(_.get(params.data, params.colDef.field || 'createdAt'))
    .tz('Asia/Hong_Kong')
    .startOf('day')
    .toDate();
};
const dateTimeValueGetter = (params: ValueGetterParams) => {
  if (!params.data) return;
  if (!_.get(params.data, params.colDef.field || 'createdAt')) return;

  return moment(_.get(params.data, params.colDef.field || 'createdAt'))
    .tz('Asia/Hong_Kong')
    .toDate();
};

const dateValueFormatter = (params: ValueFormatterParams) => {
  if (!params.value) return '';
  return moment(params.value).format('YYYY-MM-DD');
};
const dateTimeValueFormatter = (params: ValueFormatterParams) => {
  if (!params.value) return '';
  return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
};

const filterParams: { filters: IFilterDef[] } = {
  filters: [
    {
      filter: 'agDateColumnFilter',
      filterParams: { newRowsAction: 'keep', inRangeInclusive: true },
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: { newRowsAction: 'keep' },
    },
  ],
};

export const dateColumn: ColDef = {
  cellEditor: 'dateEditor',
  initialWidth: 128,
  minWidth: 128,
  suppressCellFlash: true,
  filterParams,
  valueFormatter: dateValueFormatter,
  valueGetter: dateValueGetter,
  width: 128,
};

export const dateTimeColumn: ColDef = {
  ...dateColumn,
  initialWidth: 172,
  minWidth: 172,
  valueFormatter: dateTimeValueFormatter,
  valueGetter: dateTimeValueGetter,
  width: 172,
};
