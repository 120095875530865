import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AllModules,
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  Module,
} from '@ag-grid-enterprise/all-modules';
import { AgGridAngular } from '@ag-grid-community/angular';
import { gridOptions } from './grid-options';
import { Observable, of } from 'rxjs';
import { IServerSideDatasource } from '@ag-grid-enterprise/all-modules';
import { exhaustMap, take, tap } from 'rxjs/operators';
import { HttpService } from '../../http/http.service';
import { MatDialog } from '@angular/material/dialog';
import { LogsDialogComponent } from '../logs-dialog/logs-dialog.component';

interface IEvent {
  action: string;
  data: unknown;
}

@Component({
  selector: 'up-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridComponent implements OnInit, OnChanges {
  @ViewChild('agGrid') readonly agGrid!: AgGridAngular;

  @Input() collection!: string;
  @Input() columnDefs: ColDef[] = [];
  @Input() rowData$!: Observable<any>;
  @Input() isServerSide!: boolean;

  @Output() action = new EventEmitter<IEvent>();

  modules: Module[] = AllModules as Module[];
  gridOptions: GridOptions = gridOptions;
  context: { componentParent: GridComponent } = { componentParent: this };

  constructor(private http: HttpService<any>, private dialog: MatDialog) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.agGrid.cellValueChanged.subscribe((params) => {
        const { field } = params.colDef;
        const id = params.data._id;
        const value = params.data[field];
        const body = { $set: { [field]: value } };
        this.http.update
          .byId({
            collection: this.collection,
            id,
            body,
            options: {
              failureMessage: '更新紀錄失敗',
              skipConfirm: true,
              successMessage: '更新紀錄成功',
            },
          })
          .subscribe();
        this.emitAction({ action: 'VALUE_CHANGED', data: params });
      });
      // this.handleDragAndDrop();
    });
  }

  ngOnChanges(changes: any): void {
    if (changes.columnDefs && !changes.collection?.firstChange) {
      this.agGrid?.api.setColumnDefs([
        { field: '_id', hide: true },
        { type: ['infoButtonColumn'] },
        { type: ['createdAtColumn'] },
        { type: ['avatarColumn'] },
        { type: ['nameColumn'] },

        ...this.columnDefs,

        { type: ['addButtonColumn'] },
        { type: ['refreshButtonColumn'] },
      ]);
      if (this.isServerSide)
        this.agGrid?.api.setServerSideDatasource(this.datasource);
      else this.rowData$ = this.rowData$ || this.getRowData$();
    }
    // if (changes.rowData$) this.rowData$ = changes.rowData$?.currentValue;
  }

  gridReady($event: GridReadyEvent): void {
    $event.api.closeToolPanel();
    $event.api.setColumnDefs([
      { field: '_id', hide: true },
      { type: ['infoButtonColumn'] },
      { type: ['createdAtColumn'] },
      { type: ['avatarColumn'] },
      { type: ['nameColumn'] },

      ...this.columnDefs,

      { type: ['addButtonColumn'] },
      { type: ['refreshButtonColumn'] },
    ]);
    if (this.isServerSide) $event.api.setServerSideDatasource(this.datasource);
    // else this.rowData$ = this.rowData$ || this.getRowData$();
  }

  emitAction($event: IEvent): void {
    const { action, data } = $event;
    this.action.emit({ action, data });
    console.log($event);
  }

  get datasource(): IServerSideDatasource {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const rowData: any[] = [];
        params.success({ rowData, rowCount: rowData.length });
      },
    };
  }

  getRowData$(): Observable<any> {
    const columns = this.agGrid?.columnApi?.getAllColumns() || [];

    // PROJECT
    const $project: Record<string, number> = {
      avatar: 1,
      name: 1,
      createdAt: 1,
      status: 1,
    };
    for (let i = 0; i < columns.length; i++) {
      const { field } = columns[i].getColDef();
      if (field) $project[field] = 1;
    }

    const aggregate: any[] = [{ $match: {} }, { $sort: { createdAt: -1 } }];
    // aggregate.push({ $project });

    return this.http
      .aggregate({
        collection: this.collection,
        body: aggregate,
      })
      .pipe(tap((data) => console.log(data)));
  }

  rowSelected($event: any): void {
    const [selectedRow] = this.agGrid.api.getSelectedRows();
    if (selectedRow?._id === $event.data?._id)
      this.emitAction({ action: 'SELECT', data: selectedRow });
  }

  get selectedRow(): any {
    return this.agGrid.api.getSelectedRows();
  }

  copyOneById(params: ICellRendererParams): any {
    const id = params.data._id;
    const doc$ = this.http.find.byId({ collection: this.collection, id });
    doc$
      .pipe(
        exhaustMap((doc) =>
          this.http.insert.one({
            collection: this.collection,
            body: {
              ...doc,
              _id: undefined,
              createdAt: undefined,
              createdBy: undefined,
            },
            options: {
              failureMessage: '複製紀錄失敗',
              skipConfirm: true,
              successMessage: '複製紀錄成功',
            },
          })
        )
      )
      .pipe(take(1))
      .subscribe((doc) =>
        this.agGrid.api.applyTransaction({ add: [doc], addIndex: 0 })
      );
  }

  insertOne(): any {
    this.http.insert
      .one({
        collection: this.collection,
        options: {
          failureMessage: '創建紀錄失敗',
          skipConfirm: true,
          successMessage: '創建紀錄成功',
        },
      })
      .pipe(take(1))
      .subscribe((doc) =>
        this.agGrid.api.applyTransaction({ add: [doc], addIndex: 0 })
      );
  }

  deleteOneById(params: ICellRendererParams): any {
    const id = params.data._id;
    const node = params.node.data;
    this.http.delete
      .byId({
        collection: this.collection,
        id,
        options: {
          failureMessage: '刪除紀錄失敗',
          skipConfirm: true,
          successMessage: '刪除紀錄成功',
        },
      })
      .pipe(take(1))
      .subscribe(() => this.agGrid.api.applyTransaction({ remove: [node] }));
  }

  openLogsDialog(documentKey: string): void {
    this.dialog.open(LogsDialogComponent, {
      data: { collection: this.collection, documentKey },
      height: '640px',
      maxHeight: 'calc(100vh - 72px)',
      panelClass: 'dialog-p0',
      width: '360px',
    });
  }
}
