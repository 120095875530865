import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as NavigationsActions from './navigations.actions';
import { NavigationsEntity } from './navigations.models';

export const NAVIGATIONS_FEATURE_KEY = 'navigations';

export interface State extends EntityState<NavigationsEntity> {
  selectedId?: string | number;
  selectedApp?: string | number;
  selectedNavItem?: string | number;
  // selectedNav?: string | number;
  loaded: boolean; // has the Navigations list been loaded
  error?: string | null; // last known error (if any)
}

export interface NavigationsPartialState {
  readonly [NAVIGATIONS_FEATURE_KEY]: State;
}

export const navigationsAdapter: EntityAdapter<NavigationsEntity> =
  createEntityAdapter<NavigationsEntity>();

export const initialState: State = navigationsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const navigationsReducer = createReducer(
  initialState,
  on(NavigationsActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(NavigationsActions.loadNavigationsSuccess, (state, { navigations }) =>
    navigationsAdapter.setAll(navigations, { ...state, loaded: true })
  ),
  on(NavigationsActions.loadNavigationsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(NavigationsActions.selectNavigation, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(NavigationsActions.selectApplication, (state, { id }) => ({
    ...state,
    selectedApp: id,
  })),
  on(NavigationsActions.selectNavItem, (state, { id }) => ({
    ...state,
    selectedNavItem: id,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return navigationsReducer(state, action);
}
