import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as GridActions from './grid.actions';
import { GridEntity } from './grid.models';

export const GRID_FEATURE_KEY = 'grid';

export interface State extends EntityState<GridEntity> {
  selectedId?: string | number; // which Grid record has been selected
  loaded: boolean; // has the Grid list been loaded
  error?: string | null; // last known error (if any)
}

export interface GridPartialState {
  readonly [GRID_FEATURE_KEY]: State;
}

export const gridAdapter: EntityAdapter<GridEntity> = createEntityAdapter<GridEntity>();

export const initialState: State = gridAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const gridReducer = createReducer(
  initialState,
  on(GridActions.init, (state) => ({ ...state, loaded: false, error: null })),
  on(GridActions.loadGridSuccess, (state, { grid }) =>
    gridAdapter.setAll(grid, { ...state, loaded: true })
  ),
  on(GridActions.loadGridFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return gridReducer(state, action);
}
