import {
  ColDef,
  GridOptions,
  ServerSideStoreType,
} from '@ag-grid-enterprise/all-modules';
import { columnTypes } from './column-types';
import { headers } from './headers/headers.module';
import { renderers } from './renderers/renderers.module';
import { editors } from './editors/editors.module';
import { IFilterDef } from '@ag-grid-enterprise/all-modules';
import { localeTextTC } from './i18n/zh-Hant-HK.i18n';

const frameworkComponents = { ...headers, ...renderers, ...editors };

const filterParams: { filters: IFilterDef[] } = {
  filters: [
    { filter: 'agTextColumnFilter', filterParams: { newRowsAction: 'keep' } },
    { filter: 'agSetColumnFilter', filterParams: { newRowsAction: 'keep' } },
  ],
};

const defaultColDef: ColDef = {
  cellEditor: 'genericEditor',
  editable: true,
  enablePivot: true,
  enableRowGroup: true,
  enableValue: true,
  filter: 'agMultiColumnFilter',
  filterParams,
  initialWidth: 192,
  resizable: true,
  sortable: true,
  width: 192,
};

const statusBar = {
  statusPanels: [
    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
    { statusPanel: 'agAggregationComponent', align: 'right' },
  ],
};

export const gridOptions: GridOptions = {
  animateRows: true,
  applyColumnDefOrder: true,
  columnTypes,
  defaultColDef,
  enableCellChangeFlash: true,
  enableCharts: true,
  enableRangeSelection: true,
  frameworkComponents,
  localeText: localeTextTC,
  rowDragManaged: true,
  rowGroupPanelShow: 'always',
  rowSelection: 'single',
  serverSideStoreType: ServerSideStoreType.Partial,
  sideBar: true,
  statusBar,
  suppressCopyRowsToClipboard: true,
  undoRedoCellEditing: true,
};
