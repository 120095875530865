import { Component } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { MatDialog } from '@angular/material/dialog';
import { FilesDialogComponent } from '../../../files-dialog/files-dialog.component';

@Component({
  selector: 'up-files-editor',
  templateUrl: './files-editor.component.html',
  styleUrls: ['./files-editor.component.scss'],
})
export class FilesEditorComponent implements ICellEditorAngularComp {
  private oldValue!: string[];

  public params!: ICellEditorParams;

  constructor(private dialog: MatDialog) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.oldValue = Array.isArray(params.value)
      ? [...params.value]
      : [params.value];
    this.openFilesDialog();
  }

  getValue(): any[] {
    return this.params.value;
  }

  isPopup(): boolean {
    return true;
  }

  isCancelAfterEnd(): boolean {
    const oldValue = JSON.stringify(this.oldValue);
    const newValue = JSON.stringify(this.params.value);
    if (oldValue === newValue) return true;
    return false;
  }

  openFilesDialog(): void {
    const { entity } = this.params.context.componentParent;

    const dialog = this.dialog.open(FilesDialogComponent, {
      data: { params: this.params, entity },
      height: '640px',
      maxHeight: 'calc(100vh - 72px)',
      panelClass: 'dialog-p0',
      width: '360px',
    });
    dialog.afterClosed().subscribe((fileIds) => {
      if (fileIds) this.params.value = fileIds;
      this.params.stopEditing();
    });
  }
}
