import { Component } from '@angular/core';
import { routeAnimations } from '@up/n-angular';
import { createClient } from 'contentful';

export const contentful = createClient({
  space: 'oi3nzrb3hv7q',
  accessToken: '5Q5zpQthzEn2BVZWOQsDQWNmWOB_6YijEHfrkvYsA5E',
});

@Component({
  selector: 'up-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
})
export class AppComponent {}
