import { ColDef } from '@ag-grid-enterprise/all-modules';

export const descriptionColumn: ColDef = {
  cellEditor: 'agLargeTextCellEditor',
  field: 'description',
  headerName: '描述',
  initialWidth: 192,
  maxWidth: 192,
  minWidth: 192,
  width: 192,
};
