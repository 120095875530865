import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

// ROUTER SELECTORS
export interface RouterState {
  router: RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<
  RouterState,
  RouterReducerState<any>
>('router');

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = getSelectors(selectRouter);
