import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';

import * as CredentialsFeature from './credentials.reducer';
import * as CredentialsActions from './credentials.actions';
import { CredentialsService } from '../credentials.service';
import { exhaustMap, map, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { CredentialsFacade } from './credentials.facade';

@Injectable()
export class CredentialsEffects {
  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CredentialsActions.authenticate),
      fetch({
        run: ({ credential }) => {
          return this.cS.authenticate(credential).pipe(
            exhaustMap((credentials) => {
              credentials = credentials.map((credential) => ({
                ...credential,
                id: credential._id,
              }));
              const id = credentials[0].id;

              return of(
                CredentialsActions.authenticateSuccess({ credentials }),
                CredentialsActions.selectCredential({ id })
              );
            })
          );
        },

        onError: (action, error) =>
          CredentialsActions.authenticateFailure({ error }),
      })
    )
  );
  reloadSelectedCredentialPayload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CredentialsActions.reloadSelectedCredentialPayload),
      fetch({
        run: ({ payload }) => {
          return payload
            ? CredentialsActions.reloadSelectedCredentialPayloadSuccess({
                payload: { ...payload, id: payload._id },
              })
            : this.cF.selectedCredential$.pipe(
                take(1),
                exhaustMap((credential) =>
                  this.cS.getPayload(credential?._id as string)
                ),
                map((payload) =>
                  CredentialsActions.reloadSelectedCredentialPayloadSuccess({
                    payload: { ...payload, id: payload._id },
                  })
                )
              );
        },

        onError: (action, error) => {
          console.error(error.message);
          return CredentialsActions.reloadSelectedCredentialPayloadFailure({
            error,
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private cF: CredentialsFacade,
    private cS: CredentialsService
  ) {}
}
