import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { Observable, of, Subject } from 'rxjs';
import * as pluralize from 'pluralize';
import { debounceTime, exhaustMap, map } from 'rxjs/operators';
import { HttpService } from '../../../../http/http.service';

@Component({
  selector: 'up-reference-editor',
  templateUrl: './reference-editor.component.html',
  styleUrls: ['./reference-editor.component.scss'],
})
export class ReferenceEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;

  private cellRendererParams!: { collection?: string; displayAs?: string };
  private field!: string;
  private text$ = new Subject<string>();
  private oldValue!: any[];
  private newValue!: any[];

  public params!: ICellEditorParams;
  public values$!: Observable<any[]>;
  public displayAs = 'name';
  public filteredDocs$ = this.getFilteredDocs$();

  constructor(private readonly http: HttpService<any>) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.field = params.colDef?.field || '';
    this.cellRendererParams = params.colDef?.cellRendererParams;
    this.oldValue = params.value ? [...params.value] : [];
    this.newValue = params.value ? [...params.value] : [];
    this.values$ = this.serialize(params.value);
    this.displayAs = params.colDef?.cellRendererParams?.displayAs || 'name';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input && this.input.element.nativeElement.focus();
      this.input && this.input.element.nativeElement.select();
    });
  }

  getValue(): any[] {
    if (!this.newValue) return [];
    return Array.isArray(this.newValue) ? this.newValue : [this.newValue];
  }

  isPopup(): boolean {
    return true;
  }

  isCancelAfterEnd(): boolean {
    const oldValue = JSON.stringify(this.oldValue);
    const newValue = JSON.stringify(this.newValue);
    if (oldValue === newValue) return true;

    return false;
  }

  serialize(values: unknown[]): Observable<any> {
    if (!values) return of([]);

    const collection =
      this.cellRendererParams?.collection || pluralize(this.field);
    values = Array.isArray(values) ? values : [values];
    if (!collection) return of([]);

    return this.http
      .aggregate({
        collection,
        body: [
          { $match: { _id: { $in: values } } },
          { $project: { [this.displayAs]: 1 } },
        ],
      })
      .pipe(
        map((docs) =>
          values.map((value) => docs.filter((doc) => doc._id === value)[0])
        )
      );
  }

  onInput(): void {
    this.text$.next(this.input.element.nativeElement.value);
  }

  optionSelected($event: any): void {
    const { value } = $event.option;
    this.newValue.push(value);
    this.values$ = this.serialize(this.newValue);
    setTimeout(() => {
      this.input.element.nativeElement.value = null;
      this.text$.next('');
    });
  }

  removed(index: number) {
    this.newValue.splice(index, 1);
    this.values$ = this.serialize(this.newValue);
    this.ngAfterViewInit();
  }

  onEnter(): void {
    const { value } = this.input.element.nativeElement;
    // if (!value) this.params.api.stopEditing();
  }

  getFilteredDocs$(): Observable<any[]> {
    return this.text$.asObservable().pipe(
      debounceTime(400),
      exhaustMap((text) => {
        if (!text) return of([]);

        let { collection, displayAs } =
          this.params.colDef.cellRendererParams || {};
        collection =
          collection || pluralize(this.params.colDef.field as string);
        displayAs = displayAs || 'name';

        const queryParams = {
          match: { [displayAs]: { $regex: `${text}`, $options: `i` } },
          project: { name: `$${displayAs}` },
          limit: 10,
        };

        return this.http.find.by({ collection, queryParams });
      })
    );
  }
}
