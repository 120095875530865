import { createAction, props } from '@ngrx/store';
import { GridEntity } from './grid.models';

export const init = createAction('[Grid Page] Init');

export const loadGridSuccess = createAction(
  '[Grid/API] Load Grid Success',
  props<{ grid: GridEntity[] }>()
);

export const loadGridFailure = createAction(
  '[Grid/API] Load Grid Failure',
  props<{ error: any }>()
);
