import { ColDef } from '@ag-grid-enterprise/all-modules';
import { dateColumn } from '../date';

export const createdAtColumn: ColDef = {
  editable: false,
  field: 'createdAt',
  headerName: '創建日期',
  initialWidth: 128,
  maxWidth: 128,
  minWidth: 128,
  pinned: 'left',
  resizable: false,
  suppressCellFlash: true,
  suppressMovable: true,
  suppressNavigable: true,
  width: 128,
  ...dateColumn,
};
