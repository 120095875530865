import { ColDef } from '@ag-grid-enterprise/all-modules';

export const infoButtonColumn: ColDef = {
  cellRenderer: 'infoButtonRenderer',
  cellStyle: { padding: 0 },
  editable: false,
  filter: false,
  headerComponent: 'newButtonHeader',
  initialWidth: 98,
  maxWidth: 98,
  minWidth: 98,
  pinned: 'left',
  resizable: false,
  rowDrag: (params) => !params.node.group,
  sortable: false,
  suppressCellFlash: true,
  suppressMenu: true,
  suppressMovable: true,
  suppressNavigable: true,
  width: 98,
};
