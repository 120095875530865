import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { IRequest } from '../http.module';
import { nHttpPipe } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class DeleteService<T> {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  // FIND BY ID AND DELETE
  byId(request: Partial<IRequest>): Observable<T> {
    const { collection, id, queryParams, options, ...req } = new IRequest(
      request
    );
    const { rawQueryParams } = req;
    const { byPost, version, skipConfirm } = options || {};

    const params = byPost === false ? ((queryParams || {}) as HttpParams) : {};
    const body = { queryParams: rawQueryParams };
    const url =
      byPost === false
        ? `api/v${version || 1}/collections/${collection}/id/${id}`
        : `api/v${version || 1}/collections/${collection}/delete/id/${id}`;

    const action =
      byPost === false
        ? this.http.delete(url, { params })
        : this.http.post(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // FIND ONE AND DELETE
  one(request: Partial<IRequest>): Observable<T> {
    const { collection, queryParams, options, ...req } = new IRequest(request);
    const { rawQueryParams } = req;
    const { byPost, version, skipConfirm } = options || {};

    const params = byPost === false ? ((queryParams || {}) as HttpParams) : {};
    const body = { queryParams: rawQueryParams };
    const url =
      byPost === false
        ? `api/v${version || 1}/collections/${collection}/one`
        : `api/v${version || 1}/collections/${collection}/delete/one`;

    const action =
      byPost === false
        ? this.http.delete(url, { params })
        : this.http.post(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // DELETE MANY
  many(request: Partial<IRequest>): Observable<T[]> {
    const { collection, queryParams, options, ...req } = new IRequest(request);
    const { rawQueryParams } = req;
    const { byPost, version, skipConfirm } = options || {};

    const params = byPost === false ? ((queryParams || {}) as HttpParams) : {};
    const body = { queryParams: rawQueryParams };
    const url =
      byPost === false
        ? `api/v${version || 1}/collections/${collection}/many`
        : `api/v${version || 1}/collections/${collection}/delete/many`;

    const action =
      byPost === false
        ? this.http.delete(url, { params })
        : this.http.post(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T[]>;
  }
}
