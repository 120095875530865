import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCredentials from './+state/credentials.reducer';
import { CredentialsEffects } from './+state/credentials.effects';
import { CredentialsFacade } from './+state/credentials.facade';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterFacade } from '../store/+state/router.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCredentials.CREDENTIALS_FEATURE_KEY,
      fromCredentials.reducer
    ),
    EffectsModule.forFeature([CredentialsEffects]),
    ReactiveFormsModule,
  ],
  providers: [CredentialsFacade, RouterFacade],
})
export class CredentialsModule {}
