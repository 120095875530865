import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { IRequest } from '../http.module';
import { nHttpPipe } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService<T> {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  // UPLOAD ONE (TODO)
  one(request: Partial<IRequest>): Observable<T> {
    const { options, queryParams } = new IRequest(request);
    let { body } = new IRequest(request);
    const { version } = options || {};

    const params = (queryParams || {}) as HttpParams;
    body = { ...body, queryParams: params };
    const url = `api/v${version || 1}/upload/one`;

    // SERIALIZE
    const formData = new FormData();
    for (const key in body) formData.append(key, body[key]);
    body = formData;

    return this.http
      .post(url, body, { params: { ...params, isCrypted: 'false' } })
      .pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // UPLOAD MANY
  many(request: Partial<IRequest>): Observable<T[]> {
    const { options, queryParams } = new IRequest(request);
    let { body } = new IRequest(request);
    const { version } = options || {};

    const params = (queryParams || {}) as HttpParams;
    body = { ...body, queryParams: params };
    const url = `api/v${version || 1}/upload/many`;

    // SERIALIZE
    const formData = new FormData();
    for (const key in body) {
      if (body[key] instanceof FileList) {
        const files = body[key];
        for (let i = 0; i < files.length; i++) formData.append(key, files[i]);
        continue;
      }
      formData.append(key, body[key]);
    }
    body = formData;

    return this.http
      .post(url, body, { params: { ...params, isCrypted: 'false' } })
      .pipe(nHttpPipe(request, this.snackBar)) as Observable<T[]>;
  }
}
