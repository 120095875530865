import { ICellEditorParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'up-mobile-unique-editor',
  templateUrl: './mobile-unique-editor.component.html',
  styleUrls: ['./mobile-unique-editor.component.scss'],
})
export class MobileUniqueEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;

  params!: ICellEditorParams;
  value!: string;
  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = !params.value ? '' : params.value;
    this.form = this.formBuilder.group({ value: [this.value] });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input && this.input.element.nativeElement.focus();
      this.input && this.input.element.nativeElement.select();
    });
  }

  getValue(): string | undefined {
    if (this.control.value === null) return undefined;
    return `${this.control.value}`;
  }

  isCancelAfterEnd(): boolean {
    if (this.value === this.control.value) return true;

    // VALIDATION
    const mobileRegExp = /^\+\d{1,3}-\d{5,11}$/;
    const isValid = mobileRegExp.test(this.control.value);
    if (!isValid) {
      this.snackBar.open('流動電話號碼格式不正確', '', {
        panelClass: 'fg-red-500',
      });
      return true;
    }

    return false;
  }

  get control(): FormControl {
    return this.form.controls.value as FormControl;
  }
}
