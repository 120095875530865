import { IHeaderParams } from '@ag-grid-enterprise/all-modules';
import { Component } from '@angular/core';
import { GridComponent } from '../../grid.component';
import { IHeaderAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'up-new-button-header',
  templateUrl: './new-button-header.component.html',
  styleUrls: ['./new-button-header.component.scss'],
})
export class NewButtonHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    return true;
  }

  click(): void {
    const parent: GridComponent = this.params.context.componentParent;
    parent.insertOne();
    parent.emitAction({ action: 'NEW', data: {} });
  }
}
