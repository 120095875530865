import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NAVIGATIONS_FEATURE_KEY,
  State,
  navigationsAdapter,
} from './navigations.reducer';

// Lookup the 'Navigations' feature state managed by NgRx
export const getNavigationsState = createFeatureSelector<State>(
  NAVIGATIONS_FEATURE_KEY
);

const { selectAll, selectEntities } = navigationsAdapter.getSelectors();

export const getNavigationsLoaded = createSelector(
  getNavigationsState,
  (state: State) => state.loaded
);

export const getNavigationsError = createSelector(
  getNavigationsState,
  (state: State) => state.error
);

export const getAllNavigations = createSelector(
  getNavigationsState,
  (state: State) => selectAll(state)
);

export const getNavigationsEntities = createSelector(
  getNavigationsState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getNavigationsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getNavigationsEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getSelectedApp = createSelector(
  getNavigationsState,
  (state: State) => state.selectedApp
);

export const getSelectedApplcation = createSelector(
  getNavigationsEntities,
  getSelectedId,
  getSelectedApp,
  (entities, selectedId, selectedApp) =>
    selectedId && selectedApp
      ? entities[selectedId]?.children?.filter(
          (app) => app.id === selectedApp
        )[0]
      : undefined
);

export const getSelectedNavItem = createSelector(
  getNavigationsState,
  getSelectedApplcation,
  (state: State, selectedApplcation) =>
    state.selectedNavItem && selectedApplcation
      ? selectedApplcation?.children?.filter(
          (navItem) => navItem.id === state.selectedNavItem
        )[0]
      : undefined
);
