import { Component, OnInit } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-enterprise/all-modules';
import { MatDialog } from '@angular/material/dialog';
import { HtmlDialogComponent } from './html-dialog/html-dialog.component';

@Component({
  selector: 'up-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent implements ICellEditorAngularComp {
  private oldValue!: string;

  public params!: ICellEditorParams;

  constructor(private dialog: MatDialog) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.oldValue = params.value;
    this.openFilesDialog();
  }

  getValue(): any[] {
    return this.params.value;
  }

  isPopup(): boolean {
    return true;
  }

  isCancelAfterEnd(): boolean {
    const oldValue = `${this.oldValue}`.trim();
    const newValue = `${this.params.value}`.trim();
    if (oldValue === newValue) return true;
    return false;
  }

  openFilesDialog(): void {
    const { entity } = this.params.context.componentParent;

    const dialog = this.dialog.open(HtmlDialogComponent, {
      data: { params: this.params, entity },
      height: '640px',
      maxHeight: 'calc(100vh - 72px)',
      panelClass: 'dialog-p0',
      width: '1080px',
      maxWidth: 'calc(100vw - 72px)',
    });
    dialog.afterClosed().subscribe((html) => {
      if (html !== undefined) this.params.value = html;
      this.params.stopEditing();
    });
  }
}
