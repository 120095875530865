import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { GridComponent } from '../../grid.component';

@Component({
  selector: 'up-more-button-renderer',
  templateUrl: './more-button-renderer.component.html',
  styleUrls: ['./more-button-renderer.component.scss'],
})
export class MoreButtonRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  click(action: string): void {
    const parent: GridComponent = this.params.context.componentParent;
    switch (action) {
      case 'LOG':
        parent.openLogsDialog(this.params.data._id);
        break;
      case 'COPY':
        parent.copyOneById(this.params);
        break;
      case 'DELETE':
        parent.deleteOneById(this.params);
        break;
      default:
    }
    parent.emitAction({ action, data: this.params.data });
  }
}
