import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((mod) => mod.ProfileModule),
  },
];
@NgModule({
  declarations: [],
  imports: [
    // ROUTER
    RouterModule.forChild(routes),
  ],
})
export class IAMModule {}
