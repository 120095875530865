import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromNavigations from './+state/navigations.reducer';
import { NavigationsEffects } from './+state/navigations.effects';
import { NavigationsFacade } from './+state/navigations.facade';
import { navigations } from './navigations';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromNavigations.NAVIGATIONS_FEATURE_KEY,
      fromNavigations.reducer
    ),
    EffectsModule.forFeature([NavigationsEffects]),
  ],
  providers: [NavigationsFacade],
})
export class NavigationsModule {
  constructor(private nF: NavigationsFacade) {
    this.nF.init();
  }
}
