import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { of } from 'rxjs';
import { navigations as defaultNavs } from '../navigations';

import * as NavigationsActions from './navigations.actions';
import * as NavigationsFeature from './navigations.reducer';

@Injectable()
export class NavigationsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationsActions.init),
      fetch({
        run: ({ navigations }) => {
          return of(
            NavigationsActions.loadNavigationsSuccess({
              navigations: [navigations || defaultNavs],
            }),
            NavigationsActions.selectNavigation({
              id: navigations?.id || defaultNavs.id,
            })
          );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return NavigationsActions.loadNavigationsFailure({ error });
        },
      })
    )
  );

  constructor(private readonly actions$: Actions) {}
}
