import { IEnvironment } from '@up/n-angular';

export const environment: IEnvironment = {
  production: true,
  secret: 'hvu8hQDiCsXs9PbhuqgCwmFXLjWg2Dfm',
  stage: 'dev',
  stripe: {
    pubKey:
      'pk_test_51HCIzpLhVhXVC2sYeQ4KW2zXn3Lj6OYxOzCFVGcJIwBdAFDY8SJHOV9HPMndYMtGvtXqvGKI9sMmgq0GJ4utDLjh00FLXpkWsB',
  },
};
