import { ICellEditorParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'up-password-editor',
  templateUrl: './password-editor.component.html',
  styleUrls: ['./password-editor.component.scss'],
})
export class PasswordEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;

  params!: ICellEditorParams;
  value!: string;
  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = '';
    this.form = this.formBuilder.group({ value: [this.value] });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input && this.input.element.nativeElement.focus();
      this.input && this.input.element.nativeElement.select();
    });
  }

  getValue(): string | undefined {
    if (this.control.value === null) return undefined;
    return `${this.control.value}`;
  }

  isCancelAfterEnd(): boolean {
    if (!this.control.value) return true;
    // if (this.value === this.control.value) return true;

    return false;
  }

  get control(): FormControl {
    return this.form.controls.value as FormControl;
  }
}
