<div fxFlexFill fxLayout="column">
  <!-- TOOLBAR -->
  <mat-toolbar
    class="bg-primary-50 fg-primary-700 mat-elevation-z2"
    fxLayout="row"
    style="z-index: 100"
  >
    <button mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <div fxFlex></div>
    <button mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
  </mat-toolbar>
  <!-- TOOLBAR -->

  <div fxFlex>
    <kendo-editor [formControl]="control" style="height: 100%">
      <kendo-toolbar [overflow]="true">
        <!-- UNDO / REDO -->
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <!-- UNDO / REDO -->

        <!-- TEXT DECORATION -->
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorUnderlineButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorStrikethroughButton
          ></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>

        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button
            kendoEditorSubscriptButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorSuperscriptButton
          ></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <!-- TEXT DECORATION -->

        <!-- ALIGNMENT -->
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button
            kendoEditorAlignLeftButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorAlignCenterButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorAlignRightButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorAlignJustifyButton
          ></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <!-- ALIGNMENT -->

        <kendo-toolbar-dropdownlist
          *ngIf="false"
          kendoEditorFormat
        ></kendo-toolbar-dropdownlist>
        <kendo-toolbar-dropdownlist
          kendoEditorFontSize
        ></kendo-toolbar-dropdownlist>
        <kendo-toolbar-dropdownlist
          kendoEditorFontFamily
        ></kendo-toolbar-dropdownlist>
        <kendo-toolbar-colorpicker
          kendoEditorForeColor
          view="gradient"
        ></kendo-toolbar-colorpicker>
        <kendo-toolbar-colorpicker
          kendoEditorBackColor
          view="gradient"
        ></kendo-toolbar-colorpicker>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button
            kendoEditorInsertUnorderedListButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorInsertOrderedListButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button
            kendoEditorCreateLinkButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>

        <!-- TABLE -->
        <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button
            kendoEditorAddColumnBeforeButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorAddColumnAfterButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorAddRowBeforeButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorAddRowAfterButton
          ></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button
            kendoEditorDeleteColumnButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorDeleteRowButton
          ></kendo-toolbar-button>
          <kendo-toolbar-button
            kendoEditorDeleteTableButton
          ></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <!-- TABLE -->

        <kendo-toolbar-button
          *ngIf="false"
          kendoEditorInsertFileButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorInsertImageButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorViewSourceButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorCleanFormattingButton
        ></kendo-toolbar-button>
      </kendo-toolbar>
    </kendo-editor>
  </div>

  <!-- ACTIONS -->
  <mat-toolbar fxLayoutAlign="end center">
    <button [mat-dialog-close] mat-button style="margin-right: 8px">
      取消
    </button>
    <button
      [color]="'accent'"
      [mat-dialog-close]="control.value"
      mat-raised-button
    >
      確定
    </button>
  </mat-toolbar>
  <!-- ACTIONS -->
</div>
