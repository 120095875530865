import { Component, Input } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { NavigationsFacade } from '../../navigations/+state/navigations.facade';
import { NavigationsEntity } from '../../navigations/+state/navigations.models';
import { LayoutComponent } from '../layout.component';

@Component({
  selector: 'up-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent {
  @Input() layout!: LayoutComponent;

  app$ = this.nF.selectedApplication$;
  navs$ = this.app$.pipe(map((navigation) => navigation?.children || []));

  constructor(private nF: NavigationsFacade) {}

  selectNavItem(nav: NavigationsEntity): void {
    this.nF.selectNavItem(nav.id);
  }

  navBack(): void {
    window.history.back();
  }
  navForward(): void {
    window.history.forward();
  }
}
