<div fxLayout="row" style="position: relative">
  <div
    [ngStyle.gt-sm]="{ top: '-6px' }"
    [ngStyle.lt-md]="{ top: '-4px' }"
    class="dev-banner bg-red-500 mat-caption fg-grey-0"
    fxLayoutAlign="center center"
  >
    <strong>{{ stage !== 'prod' ? stage.toUpperCase() : '' }}</strong>
  </div>
  <img
    [ngStyle.lt-sm]="{ 'margin-left': '-8px' }"
    class="logo"
    src="./assets/images/logo/logo.svg"
  />
  <div class="fg-grey-700" fxFlex fxLayoutAlign="end center">
    <button mat-icon-button>
      <mat-icon>language</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon [matBadge]="0">inbox</mat-icon>
    </button>
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <img src="./assets/images/avatars/avatar-01.svg" />
    </button>

    <!-- MENU -->
    <mat-menu #menu="matMenu">
      <div fxLayout="column" style="width: 192px">
        <!-- LOGGED IN AS -->
        <button
          class="mat-caption"
          fxLayout="column"
          fxLayoutAlign="center start"
          mat-menu-item
          style="line-height: 20px"
        >
          <div>目前登入用戶：</div>
          <strong>
            {{
              (cF.selectedCredential$ | async)?.payload?.username ||
                (cF.selectedCredential$ | async)?.payload?.email ||
                (cF.selectedCredential$ | async)?.payload?.mobile
            }}
          </strong>
        </button>
        <mat-divider style="margin-top: 8px"></mat-divider>
        <!-- LOGGED IN AS -->

        <a [routerLink]="['iam/profile']" mat-menu-item>
          <mat-icon>person</mat-icon>
          <span>個人檔案</span>
        </a>
        <a [routerLink]="['iam/profile']" mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>設置</span>
        </a>

        <!-- LOGOUT -->
        <mat-divider style="margin-bottom: 8px"></mat-divider>
        <a [routerLink]="['./logout']" (click)="cF.logout()" mat-menu-item>
          <mat-icon>logout</mat-icon>
          <span>登出</span>
        </a>
        <!-- LOGOUT -->
      </div>
    </mat-menu>
    <!-- MENU -->
  </div>
</div>
