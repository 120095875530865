import { ICellEditorParams } from '@ag-grid-enterprise/all-modules';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'up-html-dialog',
  templateUrl: './html-dialog.component.html',
  styleUrls: ['./html-dialog.component.scss'],
})
export class HtmlDialogComponent implements OnInit {
  private params = this.data.params;
  public form!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { params: ICellEditorParams },
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({ value: this.params.value });
  }

  get control(): FormControl {
    return this.form.controls.value as FormControl;
  }
}
