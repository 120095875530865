import { Component, OnInit, Inject } from '@angular/core';
import { ICellEditorParams } from '@ag-grid-community/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HttpService } from '../../http/http.service';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'up-files-dialog',
  templateUrl: './files-dialog.component.html',
  styleUrls: ['./files-dialog.component.scss'],
})
export class FilesDialogComponent implements OnInit {
  private params: ICellEditorParams = this.data.params;

  public isUploading = false;
  public values = Array.isArray(this.data.params.value)
    ? this.data.params.value
    : this.data.params.value
    ? [this.data.params.value]
    : [];
  public selectedFileIds = [...this.values];
  public selectedIndex = 0;
  public files$!: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { params: ICellEditorParams },
    private http: HttpService<any>
  ) {}

  ngOnInit(): void {
    this.files$ = this.getSelectedFiles$();
  }

  selectedTabChange($event: any): void {
    switch ($event.index) {
      case 0:
        this.values = this.selectedFileIds;
        this.files$ = this.getSelectedFiles$();
        break;
      case 1:
        this.files$ = this.getAllFiles$();
        break;
      default:
        this.files$ = this.getAllFiles$();
    }
  }

  getAllFiles$(): Observable<any[]> {
    const collection =
      this.params.colDef.cellRendererParams?.collection || 'files';
    const createdBy = false || null;

    const $match = { createdBy };
    const $project = {
      createdAt: 1,
      formats: 1,
      mime: 1,
      mimetype: 1,
      name: 1,
      size: 1,
      urls: 1,
    };
    return this.http.aggregate({
      collection,
      body: [
        // { $match },
        { $sort: { createdAt: -1 } },
        { $skip: 0 },
        { $limit: 256 },
        { $project },
      ],
    });
  }

  getSelectedFiles$(): Observable<any[]> {
    const collection =
      this.params.colDef.cellRendererParams?.collection || 'files';
    const ids = this.values;

    const $match = { _id: { $in: ids } };
    const $project = {
      createdAt: 1,
      formats: 1,
      mime: 1,
      mimetype: 1,
      name: 1,
      size: 1,
      urls: 1,
    };
    return this.http.aggregate({
      collection,
      body: [{ $match }, { $sort: { createdAt: -1 } }, { $project }],
    });
  }

  getFileIndex(id: string): number | string | undefined {
    if (this.selectedIndex === 0)
      return this.selectedFileIds.findIndex((fileId) => fileId === id) + 1;
    if (this.selectedIndex === 1)
      return this.selectedFileIds.includes(id) ? '✔' : undefined;
    if (this.selectedIndex !== 0) return undefined;

    return;
  }

  onFileClicked(id: string): void {
    if (this.selectedIndex === 0) {
      const index = this.selectedFileIds.findIndex((fileId) => fileId === id);
      if (index >= 0) this.selectedFileIds.splice(index, 1);
      else this.selectedFileIds.push(id);
    } else {
      const index = this.selectedFileIds.findIndex((fileId) => fileId === id);
      if (index >= 0) this.selectedFileIds.splice(index, 1);
      else {
        this.selectedFileIds.push(id);
        this.selectedFileIds = Array.from(new Set(this.selectedFileIds));
      }
    }
  }

  async onFileSelected($event: any): Promise<void> {
    const files = $event.target.files;

    const field = this.params.colDef.field || 'files';
    const body = { [field]: files };

    this.isUploading = true;
    try {
      await this.http.upload.many({ body }).subscribe();
      this.selectedIndex = 1;
      this.files$ = this.files$.pipe(tap());
    } catch (error) {
      0;
    } finally {
      this.isUploading = false;
    }
  }

  getFileType(file: any): string {
    if (
      file.mime?.split('/')[0] === 'image' ||
      file.mimetype?.split('/')[0] === 'image'
    )
      return 'image';
    else if (
      file.mime?.split('/')[1] === 'pdf' ||
      file.mimetype?.split('/')[1] === 'pdf'
    )
      return 'pdf';

    return 'image';
  }
}
