import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { CredentialsFacade } from './+state/credentials.facade';

@Injectable({
  providedIn: 'root',
})
export class LoginRedirectGuard implements CanActivate {
  constructor(private router: Router, private cF: CredentialsFacade) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.cF.selectedCredential$.pipe(
      // startWith(true),
      map((credential) => !!credential),
      map((isLoggedIn) => {
        const routes = state.url.split('/');
        routes[routes.length - 1] = 'redirect';
        isLoggedIn && this.router.navigate([routes.join('/')]);
        return true;
      }),
      catchError((error) => {
        this.cF.logout();
        this.router.navigate([state.url, 'logout']);
        return throwError(() => new Error(error));
      })
    );
  }
}
