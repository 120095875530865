<div fxFlexFill fxLayout="column">
  <!-- TOOLBAR -->
  <mat-toolbar
    class="bg-primary-50 fg-primary-700 mat-elevation-z2"
    fxLayout="row"
  >
    <button mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <div fxFlex></div>
    <button mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
  </mat-toolbar>
  <!-- TOOLBAR -->

  <!-- CONTENT -->
  <div fxFlex fxLayout="column" style="overflow-y: auto; padding-bottom: 40px">
    <div
      *ngFor="let log of logs$ | async"
      class="bd-grey-500"
      fxLayout="column"
      style="padding: 16px 16px 0 16px; border-bottom: 1px solid"
    >
      <!-- HEADER -->
      <div class="mat-caption" fxLayoutAlign="start start">
        <div fxLayout="column">
          <div>用戶：{{ log.createdBy[0]?.username || '暱名用戶' }}</div>
          <div class="fg-grey-500">{{ log.ip }}</div>
        </div>
        <div fxFlex></div>
        <div fxLayout="column" fxLayoutAlign="start end">
          <div
            class="mat-elevation-z2"
            [class.bg-blue-50]="log.operationType === 'update'"
            [class.bg-green-50]="log.operationType === 'insert'"
            [class.bg-red-50]="log.operationType === 'delete'"
            style="border-radius: 2px; padding: 2px 4px"
          >
            {{ log.operationType | uppercase }}
          </div>
          <div class="fg-grey-500">
            {{ log.createdAt | date: 'YYYY-MM-dd HH:mm:ss' }}
          </div>
        </div>
      </div>
      <!-- HEADER -->

      <!-- UPDATE DESCRIPTION -->
      <table class="mat-caption">
        <!-- SET -->
        <tr *ngFor="let property of $set(log.updateDescription) | keyvalue">
          <td class="fg-primary-700">{{ property.key }}</td>
          <td fxFlex></td>
          <td style="text-decoration: line-through">
            {{ oldValue(log.oldState || log.state, property.key) }}
          </td>
          <td style="padding: 0 16px"><mat-icon>arrow_right_alt</mat-icon></td>
          <td style="overflow-x: hidden">
            {{ property.value }}
          </td>
        </tr>
        <!-- SET -->

        <!-- INC -->
        <tr *ngFor="let property of $inc(log.updateDescription) | keyvalue">
          <td class="fg-primary-700">{{ property.key }}</td>
          <td fxFlex></td>
          <td style="text-decoration: line-through">
            {{ oldValue(log.oldState || log.state, property.key) }}
          </td>
          <td style="padding: 0 16px"><mat-icon>arrow_right_alt</mat-icon></td>
          <td style="overflow-x: hidden">
            {{
              oldValue(log.oldState || log.state, property.key) + property.value
            }}
          </td>
        </tr>
        <!-- INC -->
      </table>
      <!-- UPDATE DESCRIPTION -->
    </div>
  </div>
  <!-- CONTENT -->

  <!-- ACTIONS -->
  <mat-toolbar fxLayoutAlign="end center">
    <button [color]="'accent'" [mat-dialog-close] mat-raised-button>
      確定
    </button>
  </mat-toolbar>
  <!-- ACTIONS -->
</div>
