import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CredentialsGuard, NAngularModule } from '@up/n-angular';
import { PreloadAllModules, Route, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MemberGuard } from './guards/member.guard';
import { StoreGuard } from './guards/store.guard';

const routes: Route[] = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./public/public.module').then((mod) => mod.PublicModule),
      },
      {
        path: 'member',
        canActivate: [CredentialsGuard, MemberGuard],
        loadChildren: () =>
          import('./member/member.module').then((mod) => mod.MemberModule),
      },
      {
        path: 'store',
        canActivate: [CredentialsGuard, StoreGuard],
        loadChildren: () =>
          import('./store/store.module').then((mod) => mod.StoreModule),
      },
      {
        path: 'portal',
        loadChildren: () =>
          import('./portal/portal.module').then((mod) => mod.PortalModule),
      },
    ],
  },
  { path: 'app/member', redirectTo: 'member' },
  { path: 'app/merchant', redirectTo: 'store' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    NAngularModule.forRoot(environment),
    FlexLayoutModule,

    // SERVICE WORKER
    // environment.production
    //   ? ServiceWorkerModule.register('ngsw-worker.js', {
    //       registrationStrategy: 'registerWhenStable:30000',
    //     })
    //   : [],

    // ROUTER
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
