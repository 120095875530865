import { Injectable } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import {
  catchError,
  map,
  Observable,
  of,
  take,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { HttpService } from '../http/http.service';
import { Credential, CredentialsEntity } from './+state/credentials.models';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  mobileForm = this.fb.group({
    areaCode: ['852', [Validators.required, Validators.maxLength(3)]],
    number: [
      '',
      [
        Validators.required,
        Validators.maxLength(11),
        Validators.minLength(8),
        Validators.pattern(/^[0-9]{8,11}$/),
      ],
    ],
  });
  otpForm = this.fb.array([
    [
      '',
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^(\d|#)$/),
      ],
    ],
    [
      '',
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^(\d|#)$/),
      ],
    ],
    [
      '',
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^(\d|#)$/),
      ],
    ],
    [
      '',
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^(\d|#)$/),
      ],
    ],
    [
      '',
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^(\d|#)$/),
      ],
    ],
    [
      '',
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^(\d|#)$/),
      ],
    ],
  ]);
  credentialForm = this.fb.group({
    strategy: [
      'MOBILE+OTP',
      [
        Validators.required,
        Validators.pattern(/(USERNAME\+PASSWORDS.LOGIN|MOBILE\+OTP)/),
      ],
    ],
    identifier: ['', [Validators.required]],
    secret: ['', [Validators.required]],
  });

  secLeft$ = of(0);

  constructor(private fb: FormBuilder, private http: HttpService<any>) {}

  sendVerificationCode(credential: Partial<Credential>): Observable<any> {
    if (!this.mobileForm.valid) return of(null);

    this.mobileForm.disable();
    this.mobileForm.disable();

    this.secLeft$ = timer(0, 1000).pipe(
      take(60 + 1),
      map((sec) => 60 - sec),
      tap((sec) => {
        if (!sec) {
          this.mobileForm.enable();
          this.mobileForm.enable();
        }
      })
    );

    const { areaCode, number } = this.mobileForm.value;
    credential.identifier = `+${areaCode}-${number}`;
    return this.http
      .post('credentials/verification-code/send', {
        body: credential,
        options: { failureMessage: '登入信息不正確' },
      })
      .pipe(
        catchError((error) => {
          this.mobileForm.get('areaCode')?.enable();
          this.mobileForm.enable();
          this.secLeft$ = of(0);
          return throwError(() => new Error(error));
        })
      );
  }

  authenticate(credential: Credential): Observable<CredentialsEntity[]> {
    return this.http.post('credentials/authenticate', {
      body: credential,
      options: { failureMessage: '登入信息不正確' },
    });
  }

  validate(): Observable<CredentialsEntity[]> {
    return this.http.post('credentials/validate', {
      options: { failureMessage: '權限失效，請重新登入' },
    });
  }

  getPayload(id: string): Observable<any> {
    return this.http.find.byId({
      collection: 'users',
      id,
      queryParams: {
        match: { status: 'ACTIVE' },
        project: {
          name: 1,
          gender: 1,
          username: 1,
          email: 1,
          mobile: 1,
          roles: 1,
          expiredAt: 1,
          status: 1,
        },
      },
    });
  }
}
