import { ColDef } from '@ag-grid-enterprise/all-modules';

import { addButtonColumn } from './add-button';
import { avatarColumn } from './avatar';
import { booleanColumn } from './boolean';
import { createdAtColumn } from './created-at';
import { dateColumn, dateTimeColumn } from './date';
import { descriptionColumn } from './description';
import { filesColumn } from './files';
import { htmlColumn } from './html';
import { infoButtonColumn } from './info-button';
import { integerColumn, decimalColumn } from './number';
import { mobileColumn } from './mobile';
import { nameColumn } from './name';
import { referenceColumn } from './reference';
import { refreshButtonColumn } from './refresh-button';

export const columnTypes: { [key: string]: ColDef } = {
  addButtonColumn,
  avatarColumn,
  booleanColumn,
  createdAtColumn,
  dateColumn,
  dateTimeColumn,
  decimalColumn,
  descriptionColumn,
  filesColumn,
  htmlColumn,
  infoButtonColumn,
  integerColumn,
  mobileColumn,
  nameColumn,
  referenceColumn,
  refreshButtonColumn,
};
