import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Credential, CredentialsEntity } from './credentials.models';

export const init = createAction('[Credentials Page] Init');

export const loadCredentialsSuccess = createAction(
  '[Credentials/API] Load Credentials Success',
  props<{ credentials: CredentialsEntity[] }>()
);

export const loadCredentialsFailure = createAction(
  '[Credentials/API] Load Credentials Failure',
  props<{ error: any }>()
);

// AUTHENTICATE
export const authenticate = createAction(
  '[Credentials/API] Authenicate',
  props<{ credential: Credential }>()
);
export const authenticateSuccess = createAction(
  '[Credentials/API] Authenicate Success',
  props<{ credentials: CredentialsEntity[] }>()
);
export const authenticateFailure = createAction(
  '[Credentials/API] Authenicate Failure',
  props<{ error: any }>()
);
export const selectCredential = createAction(
  '[Credentials/API] Select Credential',
  props<{ id: string | number }>()
);
export const removeCredential = createAction(
  '[Credentials/API] Remove Credential',
  props<{ id: string | number }>()
);

// RELOAD SELECTED CREDENTIAL PAYLOAD
export const reloadSelectedCredentialPayload = createAction(
  '[Credentials/API] Reload Selected Credential Payload',
  props<{ payload?: any }>()
);
export const reloadSelectedCredentialPayloadSuccess = createAction(
  '[Credentials/API] Reload Selected Credential Payload Success',
  props<{ payload: any }>()
);
export const reloadSelectedCredentialPayloadFailure = createAction(
  '[Credentials/API] Reload Selected Credential Payload Failure',
  props<{ error: any }>()
);

export const logout = createAction('[Credentials/API] Logout');
