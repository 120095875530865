import { Inject, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CredentialsFacade } from '../credentials/+state/credentials.facade';
import { IEnvironment } from '../n-angular.module';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public socket!: Socket;

  private _host = this._environment.endPointHost || window.location.hostname;
  private _socket$ = this._cF.selectedCredential$.pipe(
    map(
      (credential) =>
        new Socket({
          url: `${window.location.protocol}//${this._host}`,
          options: { query: { accessToken: credential?.accessToken || '' } },
        })
    ),
    tap((socket) => (this.socket = socket))
  );

  constructor(
    @Inject('ENV') private _environment: IEnvironment,
    private _cF: CredentialsFacade
  ) {
    this._socket$.subscribe();
  }

  public on$(eventName: string): Observable<any> {
    const subject = new Subject<any>();
    this.socket.on(eventName, (message: any) => subject.next(message));
    return subject.asObservable();
  }
}
