import { Component, Inject, OnInit } from '@angular/core';
import { CredentialsFacade } from '../../credentials/+state/credentials.facade';
import { IEnvironment } from '../../n-angular.module';
// import { US, HK } from 'country-flag-icons/string/3x2';
@Component({
  selector: 'up-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  stage = this._environment.stage;

  constructor(
    @Inject('ENV') private _environment: IEnvironment,
    public cF: CredentialsFacade
  ) {}

  ngOnInit(): void {}
}
