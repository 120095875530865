import { ColDef, ValueGetterParams } from '@ag-grid-enterprise/all-modules';

const nameValueGetter = (params: ValueGetterParams) =>
  params.data?.name ? params.data?.name : params.data?.title;

export const nameColumn: ColDef = {
  headerName: '名稱／標題／編號',
  field: 'name',
  initialWidth: 192,
  minWidth: 192,
  tooltipField: 'name',
  valueGetter: nameValueGetter,
  width: 192,
};
