import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberEditorComponent } from './number-editor/number-editor.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { BooleanEditorComponent } from './boolean-editor/boolean-editor.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateEditorComponent } from './date-editor/date-editor.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ReferenceEditorComponent } from './reference-editor/reference-editor.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FilesEditorComponent } from './files-editor/files-editor.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { HtmlDialogComponent } from './html-editor/html-dialog/html-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GenericEditorComponent } from './generic-editor/generic-editor.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { MobileUniqueEditorComponent } from './mobile-unique-editor/mobile-unique-editor.component';
import { EmailUniqueEditorComponent } from './email-unique-editor/email-unique-editor.component';
import { BalanceEditorComponent } from './balance-editor/balance-editor.component';
import { PasswordEditorComponent } from './password-editor/password-editor.component';
import { BalanceDialogComponent } from './balance-editor/balance-dialog/balance-dialog.component';

@NgModule({
  declarations: [
    NumberEditorComponent,
    BooleanEditorComponent,
    DateEditorComponent,
    ReferenceEditorComponent,
    FilesEditorComponent,
    HtmlEditorComponent,
    HtmlDialogComponent,
    GenericEditorComponent,
    MobileUniqueEditorComponent,
    EmailUniqueEditorComponent,
    BalanceEditorComponent,
    PasswordEditorComponent,
    BalanceDialogComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,

    // MATERIAL
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMomentDateModule,
    MatToolbarModule,

    // 3RD PARTY
    EditorModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'zh-HK' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'YYYY-MM-DD HH:mm:ss' },
        display: {
          dateInput: 'YYYY-MM-DD HH:mm:ss',
          monthYearLabel: 'YYYY MMM',
        },
      },
    },
  ],
})
export class EditorsModule {}

export const editors = {
  booleanEditor: BooleanEditorComponent,
  dateEditor: DateEditorComponent,
  filesEditor: FilesEditorComponent,
  htmlEditor: HtmlEditorComponent,
  genericEditor: GenericEditorComponent,
  numberEditor: NumberEditorComponent,
  referenceEditor: ReferenceEditorComponent,
  emailUniqueEditor: EmailUniqueEditorComponent,
  mobileUniqueEditor: MobileUniqueEditorComponent,
  passwordEditor: PasswordEditorComponent,
  balanceEditor: BalanceEditorComponent,
};
