import {
  ColDef,
  ValueParserParams,
  ValueSetterParams,
} from '@ag-grid-enterprise/all-modules';

const valueParser = (params: ValueParserParams) => {
  if (params.newValue === null || params.newValue === undefined) return [];

  return Array.isArray(params.newValue) ? params.newValue : [params.newValue];
};
const valueSetter = (params: ValueSetterParams) => {
  let newValue;
  const field = params.colDef?.field || '';

  if (!params.newValue) params.data[field] = [];
  else {
    newValue = Array.isArray(params.newValue)
      ? params.newValue
      : [params.newValue];
    params.data[field] = newValue;
  }

  return true;
};

export const referenceColumn: ColDef = {
  cellEditor: 'referenceEditor',
  cellRenderer: 'referenceRenderer',
  cellStyle: { padding: '0 8px' },
  initialWidth: 172,
  maxWidth: 172,
  minWidth: 172,
  valueParser,
  valueSetter,
  width: 172,
};
