import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { CredentialsFacade } from '../../credentials/+state/credentials.facade';
import { NavigationsFacade } from '../../navigations/+state/navigations.facade';
import { LayoutComponent } from '../layout.component';

@Component({
  selector: 'up-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss'],
})
export class AppListComponent {
  @Input() layout!: LayoutComponent;

  apps$ = this.nF.selectedNavigations$.pipe(
    map((navigation) => navigation?.children || [])
  );

  constructor(private nF: NavigationsFacade, public cF: CredentialsFacade) {}

  selectApplication(id: string | number): void {
    this.nF.selectApplication(id);
    this.layout.leftDrawer.close();
  }
}
