import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { CredentialsFacade } from './+state/credentials.facade';
import { CredentialsService } from './credentials.service';

@Injectable({
  providedIn: 'root',
})
export class CredentialsGuard implements CanActivate {
  constructor(
    private cS: CredentialsService,
    private router: Router,
    private cF: CredentialsFacade
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.cS.validate().pipe(
      map((credential) => !!credential),
      catchError((error) => {
        this.cF.logout();
        this.router.navigate([state.url, 'logout']);
        return throwError(() => new Error(error));
      })
    );
  }
}
