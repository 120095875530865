<div
  class="bg-primary-50"
  fxFlexFill
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div
    [ngStyle.lt-md]="{ 'border-radius': 0 }"
    class="bg-grey-0 login-card mat-elevation-z2"
    fxLayout="row"
  >
    <!-- LOGIN -->
    <div
      [ngStyle.lt-md]="{ padding: '48px' }"
      fxFlex.lt-md="100%"
      fxFlex="420px"
      fxLayout="column"
    >
      <img src="./assets/images/logo/logo.svg" />
      <div class="mat-headline"><strong>登入</strong></div>
      <div class="mat-body-1">
        沒有帳號？ <a href="javascript:void(0)">註冊</a>
      </div>

      <!-- FORM -->
      <form fxLayout="column" style="margin-top: 24px">
        <!-- USERNAME -->
        <mat-form-field
          [appearance]="'outline'"
          *ngIf="cS.credentialForm.value.strategy.split('+')[0] === 'USERNAME'"
        >
          <mat-icon matPrefix>person</mat-icon>
          <mat-label>用戶帳號</mat-label>
          <input
            [formControl]="$any(cS.credentialForm.get('identifier'))"
            #identifierInput
            matInput
            placeholder="用戶帳號"
          />
        </mat-form-field>
        <!-- USERNAME -->

        <!-- MOBILE -->
        <form
          [formGroup]="cS.mobileForm"
          *ngIf="cS.credentialForm.value.strategy.split('+')[0] === 'MOBILE'"
        >
          <mat-form-field [appearance]="'outline'" fxFlex="80px">
            <mat-label>區號</mat-label>
            <mat-select [formControlName]="'areaCode'">
              <mat-option value="852">+852</mat-option>
              <mat-option value="86">+86</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [appearance]="'outline'" fxFlex>
            <mat-icon matPrefix>phone</mat-icon>
            <mat-label>流動電話號碼</mat-label>
            <input
              #identifierInput
              (keydown.enter)="sendVerificationCode()"
              formControlName="number"
              matInput
              maxlength="11"
              minlength="8"
              placeholder="流動電話號碼"
              type="tel"
            />
            <button
              [disabled]="cS.mobileForm.invalid || !!(cS.secLeft$ | async)"
              (click)="sendVerificationCode()"
              type="button"
              mat-icon-button
              matSuffix
              color="primary"
            >
              <mat-icon>send</mat-icon>
            </button>
            <mat-hint [align]="'end'" *ngIf="!!(cS.secLeft$ | async)">
              {{ cS.secLeft$ | async }} 後重新發送
            </mat-hint>
          </mat-form-field>
        </form>
        <!-- MOBILE -->

        <!-- PASSWORD -->
        <mat-form-field
          [appearance]="'outline'"
          *ngIf="
            cS.credentialForm.value.strategy.split('+')[1] === 'PASSWORDS.LOGIN'
          "
        >
          <mat-label>登入密碼</mat-label>
          <mat-icon matPrefix>lock</mat-icon>
          <input
            (keydown.enter)="login()"
            [formControl]="$any(cS.credentialForm.get('secret'))"
            [type]="isPasswordVisible ? 'text' : 'password'"
            matInput
            placeholder="登入密碼"
          />
          <button
            (click)="isPasswordVisible = !isPasswordVisible"
            type="button"
            mat-icon-button
            matSuffix
          >
            <mat-icon *ngIf="!isPasswordVisible" class="fg-grey-500"
              >visibility</mat-icon
            >
            <mat-icon *ngIf="isPasswordVisible" class="fg-grey-500"
              >visibility_off</mat-icon
            >
          </button>
        </mat-form-field>
        <!-- PASSWORD -->

        <!-- OTP -->
        <div
          *ngIf="cS.credentialForm.value.strategy.split('+')[1] === 'OTP'"
          fxLayout="row"
        >
          <mat-form-field
            [appearance]="'outline'"
            *ngFor="let control of cS.otpForm.controls; let i = index"
            fxFlex
          >
            <input
              (input)="onOTPInput($event, control, i)"
              (keydown.backspace)="onBackspaceKeydown(control, i)"
              [formControl]="$any(control)"
              id="otp-{{ i }}"
              matInput
              maxlength="1"
              minlength="1"
              type="tel"
            />
          </mat-form-field>
        </div>
        <!-- OTP -->

        <div class="mat-body-1" fxLayout="row" fxLayoutAlign="start center">
          <mat-checkbox *ngIf="false">Remember Me</mat-checkbox>
          <div fxFlex></div>
          <!-- <a href="javascript:void(0)">忘記密碼？</a> -->
        </div>

        <!-- BUTTON -->
        <button
          (click)="login()"
          [disabled]="cS.credentialForm.invalid || (cF.loading$ | async)"
          color="primary"
          mat-raised-button
          style="margin-top: 24px"
          type="button"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            登入
            <mat-spinner
              *ngIf="isLoading"
              [diameter]="24"
              style="margin-left: 8px"
            ></mat-spinner>
          </div>
        </button>
        <!-- BUTTON -->
      </form>
      <!-- FORM -->

      <div fxLayoutAlign="start center" style="margin-top: 24px">
        <div class="line bd-grey-300" fxFlex style="margin-right: 16px"></div>
        <div class="mat-caption fg-grey-400">選擇登入方法</div>
        <div class="line bd-grey-300" fxFlex style="margin-left: 16px"></div>
      </div>

      <!-- STRATEGY BUTTONS -->
      <div
        fxLayoutAlign="center center"
        fxLayoutGap="24px"
        style="margin-top: 24px"
      >
        <button
          (click)="selectStategy('MOBILE+OTP')"
          [color]="
            cS.credentialForm.value.strategy.split('+')[0] === 'MOBILE'
              ? 'primary'
              : ''
          "
          mat-stroked-button
        >
          <mat-icon>phone</mat-icon>
        </button>
        <button
          (click)="selectStategy('USERNAME+PASSWORDS.LOGIN')"
          [color]="
            cS.credentialForm.value.strategy.split('+')[0] === 'USERNAME'
              ? 'primary'
              : ''
          "
          mat-stroked-button
        >
          <mat-icon>person</mat-icon>
        </button>
        <button
          *ngIf="false"
          (click)="selectStategy('CREDENTIALS')"
          [color]="
            cS.credentialForm.value.strategy === 'CREDENTIALS' ? 'primary' : ''
          "
          mat-stroked-button
        >
          <mat-icon>checklist</mat-icon>
        </button>
      </div>
      <!-- STRATEGY BUTTONS -->
    </div>
    <!-- LOGIN -->

    <!-- INTRODUCTION -->
    <div
      class="bg-primary-900"
      fxFlex
      fxHide.lt-md
      fxLayoutAlign="center center"
    >
      <!-- SHAPES -->
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
      <!-- SHAPES -->

      <div style="padding: 0 56px; width: 100%">
        <div class="fg-grey-0 mat-display-1">{{ title }}</div>
        <div
          class="fg-grey-400 mat-subheading-1"
          style="padding: 24px 0; text-align: justify"
        >
          {{ description }}
        </div>
        <div
          *ngIf="caption"
          class="avatars fg-grey-400"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <img
            class="bd-primary-900"
            src="./assets/images/avatars/avatar-01.svg"
          />
          <img
            class="bd-primary-900"
            src="./assets/images/avatars/avatar-02.svg"
            style="transform: translateX(-16px)"
          />
          <img
            class="bd-primary-900"
            src="./assets/images/avatars/avatar-03.svg"
            style="transform: translateX(-32px)"
          />
          <img
            class="bd-primary-900"
            src="./assets/images/avatars/avatar-04.svg"
            style="transform: translateX(-48px)"
          />
          <div class="mat-body-1">
            {{ caption }}
          </div>
        </div>
      </div>
    </div>
    <!-- INTRODUCTION -->
  </div>
</div>
