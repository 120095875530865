<mat-drawer-container fxFlexFill>
  <!-- LEFT DRAWER -->
  <mat-drawer #leftDrawer [mode]="'over'" [opened]="false" [position]="'start'">
    <up-app-list [layout]="this" class="mat-elevation-z16"></up-app-list>
  </mat-drawer>
  <!-- LEFT DRAWER -->

  <!-- CONTENT -->
  <mat-drawer-content style="max-width: 100vw">
    <mat-drawer-container fxFlexFill>
      <!-- INNER LEFT DRAWER -->
      <mat-drawer
        [mode]="'side'"
        [opened]="lF.navList.visible$ | async"
        [position]="'start'"
        [ngStyle.gt-sm]="{ width: '64px' }"
        [ngStyle.lt-md]="{ width: '56px', maxWidth: '56px' }"
        class="nav-list-drawer mat-elevation-z4 bg-blue-grey-900"
        fxLayout="column"
      >
        <up-nav-list [layout]="this"></up-nav-list>
      </mat-drawer>
      <!-- INNER LEFT DRAWER -->

      <!-- INNER CONTENT -->
      <mat-drawer-content fxLayout="column">
        <!-- TOOLBAR -->
        <mat-toolbar
          *ngIf="lF.toolbar.visible$ | async"
          class="bg-grey-0 mat-elevation-z2"
        >
          <up-toolbar></up-toolbar>
        </mat-toolbar>
        <!-- TOOLBAR -->

        <!-- PROGRESS BAR -->
        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        <!-- PROGRESS BAR -->

        <mat-drawer-container fxFlex>
          <!-- INNER LEFT DRAWER -->
          <mat-drawer [position]="'start'"></mat-drawer>
          <!-- INNER LEFT DRAWER -->

          <!-- CONTENT -->
          <mat-drawer-content
            [@routeAnimations]="outlet.isActivated ? outlet.activatedRoute : ''"
            class="bg-grey-50"
            style="overflow-x: hidden; overflow-y: auto; position: relative"
          >
            <router-outlet #outlet="outlet"></router-outlet>
          </mat-drawer-content>
          <!-- CONTENT -->

          <!-- INNER RIGHT DRAWER -->
          <mat-drawer [position]="'end'"></mat-drawer>
          <!-- INNER RIGHT DRAWER -->
        </mat-drawer-container>
      </mat-drawer-content>
      <!-- INNER CONTENT -->
    </mat-drawer-container>
  </mat-drawer-content>
  <!-- CONTENT -->

  <!-- RIGHT DRAWER -->
  <mat-drawer #rightDrawer [mode]="'over'" [opened]="false" [position]="'end'">
    RIGHT
  </mat-drawer>
  <!-- RIGHT DRAWER -->
</mat-drawer-container>
