import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AES, enc } from 'crypto-js';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, map } from 'rxjs/operators';
import { IEnvironment } from '../../n-angular.module';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService implements HttpInterceptor {
  constructor(
    @Inject('ENV') private environment: IEnvironment,
    private deviceService: DeviceDetectorService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const { params } = req;
    if (
      this.environment.isCrypted === false ||
      params.get('isCrypted') === 'false'
    )
      return next.handle(req);

    const device = this.deviceService.getDeviceInfo();
    const body = {
      timestamp: moment().unix(),
      device,
      request: AES.encrypt(
        JSON.stringify(req.body),
        this.environment.secret
      ).toString(),
    };
    req = req.clone({ ...req, body });

    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      map((event: any) => {
        const { body } = event;
        const document = AES.decrypt(
          body.document,
          this.environment.secret
        ).toString(enc.Utf8);
        event.body = JSON.parse(document);

        return event;
      })
    );
  }
}
