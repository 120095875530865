<div
  (keydown.enter)="$event.stopPropagation()"
  (keypress.enter)="$event.stopPropagation()"
  fxFlexFill
  style="padding: 8px"
>
  <mat-form-field>
    <mat-chip-list #chipList>
      <mat-chip
        (removed)="removed(i)"
        *ngFor="let value of values$ | async; let i = index"
        [removable]="true"
      >
        {{ value[displayAs] }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        #input
        (input)="onInput()"
        (keyup.enter)="onEnter()"
        [matAutocomplete]="autoComplete"
        [placeholder]="params.colDef?.headerName || ''"
        matInput
      />

      <!-- AUTOCOMPLETE -->
      <mat-autocomplete
        #autoComplete="matAutocomplete"
        (optionSelected)="optionSelected($event)"
        autoActiveFirstOption
      >
        <mat-option *ngFor="let doc of filteredDocs$ | async" [value]="doc._id">
          {{ doc.name }}
        </mat-option>
      </mat-autocomplete>
      <!-- AUTOCOMPLETE -->
    </mat-chip-list>
  </mat-form-field>
</div>
