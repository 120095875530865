import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { IRequest } from '../http.module';
import { nHttpPipe } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateService<T> {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  // FIND BY ID AND UPDATE
  byId(request: Partial<IRequest>): Observable<T> {
    const { collection, id, queryParams, options } = new IRequest(request);
    let { body } = new IRequest(request);
    const { version, skipConfirm } = options || {};

    const params = (queryParams || {}) as HttpParams;
    body = { ...body, queryParams: params };
    const url = `api/v${version || 1}/collections/${collection}/id/${id}`;

    const action = this.http.put(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // FIND ONE AND UPDATE
  one(request: Partial<IRequest>): Observable<T> {
    const { collection, queryParams, options } = new IRequest(request);
    let { body } = new IRequest(request);
    const { version, skipConfirm } = options || {};

    const params = (queryParams || {}) as HttpParams;
    body = { ...body, queryParams: params };
    const url = `api/v${version || 1}/collections/${collection}/one`;

    const action = this.http.put(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // UPDATE MANY
  many(request: Partial<IRequest>): Observable<T> {
    const { collection, queryParams, options } = new IRequest(request);
    let { body } = new IRequest(request);
    const { version, skipConfirm } = options || {};

    const params = (queryParams || {}) as HttpParams;
    body = { ...body, queryParams: params };
    const url = `api/v${version || 1}/collections/${collection}/many`;

    const action = this.http.put(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }
}
