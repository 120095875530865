<div fxFlexFill fxLayoutAlign="center center">
  <img *ngIf="avatar$ | async as avatar" [src]="avatar.urls.xs" />

  <div
    *ngIf="!value.length && firstChar"
    class="bg-primary-500 fg-primary-50"
    fxLayoutAlign="center center"
  >
    <span class="mat-title">{{ firstChar }}</span>
  </div>
</div>
