<div fxFlexFill fxLayoutAlign="start center" style="overflow-y: auto">
  <div
    *ngFor="let file of files$ | async"
    fxLayout="row"
    style="border-radius: 4px; cursor: pointer; margin-right: 8px"
  >
    <div
      [ngSwitch]="getFileType(file)"
      fxLayout="row"
      matRipple
      style="border-radius: 4px"
    >
      <img
        *ngSwitchDefault
        [src]="file?.formats?.thumbnail?.url || file?.urls?.xs"
      />
    </div>
  </div>
</div>
