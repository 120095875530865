import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as CredentialsActions from './credentials.actions';
import { CredentialsEntity } from './credentials.models';

export const CREDENTIALS_FEATURE_KEY = 'credentials';

export interface State extends EntityState<CredentialsEntity> {
  selectedId?: string | number; // which Credentials record has been selected
  loaded: boolean; // has the Credentials list been loaded
  error?: string | null; // last known error (if any)
  loading?: boolean;
}

export interface CredentialsPartialState {
  readonly [CREDENTIALS_FEATURE_KEY]: State;
}

export const credentialsAdapter: EntityAdapter<CredentialsEntity> =
  createEntityAdapter<CredentialsEntity>();

export const initialState: State = credentialsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
});

const credentialsReducer = createReducer(
  initialState,

  // AUTHENTICATE
  on(CredentialsActions.authenticate, (state) => ({ ...state, loading: true })),
  on(CredentialsActions.authenticateSuccess, (state, { credentials }) =>
    credentialsAdapter.addMany(credentials, {
      ...state,
      loading: false,
      selectedId: credentials[0].id,
    })
  ),
  on(CredentialsActions.authenticateFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CredentialsActions.selectCredential, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(CredentialsActions.removeCredential, (state, { id }) =>
    credentialsAdapter.removeOne(id as string, {
      ...state,
      selectedId: state.selectedId === id ? undefined : state.selectedId,
    })
  ),
  on(CredentialsActions.logout, (state) => ({
    ...state,
    selectedId: undefined,
  })),

  // RELOAD PAYLOAD
  on(
    CredentialsActions.reloadSelectedCredentialPayloadSuccess,
    (state, { payload }) =>
      credentialsAdapter.upsertOne(
        { ...state.entities[payload._id], payload } as any,
        state
      )
  )
);

export function reducer(state: State | undefined, action: Action) {
  return credentialsReducer(state, action);
}
