import {
  ColDef,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from '@ag-grid-enterprise/all-modules';

const valueGetter = (params: ValueGetterParams) => {
  const field = params.colDef?.field;
  const mobile =
    field &&
    params.data &&
    typeof params.data[field] === 'object' &&
    params.data[field];
  const { areaCode, number } = mobile || {};
  if (!areaCode || !number) return '';

  return `+${areaCode}-${number}`;
};
const valueParser = (params: ValueParserParams) => {
  const value = `${params.newValue}`.trim();
  if (!value) return null;

  const regex = /^(\+\d{1,3}[-])\d{8,11}$/;
  if (!regex.test(value)) return params.oldValue;

  let [areaCode, number] = value && value.split('-');
  areaCode = areaCode && areaCode.split('+')[1];

  if (areaCode && number) return { areaCode, number };
  return params.oldValue;
};
const valueSetter = (params: ValueSetterParams) => {
  const { areaCode, number } = params.newValue || {};
  if (`+${areaCode}-${number}` === params.oldValue) return false;

  const field = params.colDef?.field;
  if (field && areaCode && number) {
    params.data[field] = { areaCode, number };
    return true;
  }
  if (field && !areaCode && !number) {
    params.data[field] = null;
    return true;
  }

  return false;
};

export const mobileColumn: ColDef = {
  cellEditor: undefined,
  field: 'mobile',
  headerName: '流動電話號碼',
  initialWidth: 160,
  maxWidth: 160,
  minWidth: 160,
  // valueGetter,
  // valueParser,
  // valueSetter,
  width: 160,
};
