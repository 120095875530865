import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as LayoutActions from './layout.actions';
import { LayoutEntity } from './layout.models';

export const LAYOUT_FEATURE_KEY = 'layout';

export interface State extends EntityState<LayoutEntity> {
  navList: { visible: boolean };
  toolbar: { visible: boolean };
}

export interface LayoutPartialState {
  readonly [LAYOUT_FEATURE_KEY]: State;
}

export const layoutAdapter: EntityAdapter<LayoutEntity> =
  createEntityAdapter<LayoutEntity>();

export const initialState: State = layoutAdapter.getInitialState({
  navList: { visible: false },
  toolbar: { visible: false },
});

const layoutReducer = createReducer(
  initialState,

  // NAV LIST
  on(LayoutActions.showNavList, (state) => ({
    ...state,
    navList: { visible: true },
  })),
  on(LayoutActions.hideNavList, (state) => ({
    ...state,
    navList: { visible: false },
  })),

  // TOOLBAR
  on(LayoutActions.showToolbar, (state) => ({
    ...state,
    toolbar: { visible: true },
  })),
  on(LayoutActions.hideToolbar, (state) => ({
    ...state,
    toolbar: { visible: false },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return layoutReducer(state, action);
}
