import {
  HttpClient,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DeleteService } from './delete/delete.service';
import { FindService } from './find/find.service';
import { IRequest } from './http.module';
import { InsertService } from './insert/insert.service';
import { UpdateService } from './update/update.service';
import { UploadService } from './upload/upload.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService<T> {
  public readonly insert = this.insertService;
  public readonly find = this.findService;
  public readonly update = this.updateService;
  public readonly delete = this.deleteService;
  public readonly upload = this.uploadService;

  constructor(
    private readonly http: HttpClient,
    private readonly insertService: InsertService<T>,
    private readonly findService: FindService<T>,
    private readonly updateService: UpdateService<T>,
    private readonly deleteService: DeleteService<T>,
    private readonly uploadService: UploadService<T>,
    private readonly snackBar: MatSnackBar
  ) {}

  countDocuments(request: Partial<IRequest>): Observable<number> {
    request = new IRequest(request);
    const { byPost, version } = request.options || {};
    const { collection, body } = request;
    const params = byPost
      ? body?.queryParams
      : (request.queryParams as HttpParams);
    const url = `api/v${
      version || 1
    }/collections/${collection}/count-documents`;
    const action =
      byPost === false
        ? this.http.get(url)
        : this.http.post(url, {}, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<number>;
  }

  aggregate(request: Partial<IRequest>): Observable<T[]> {
    request = new IRequest(request);
    const { collection, body } = request;
    const url = `api/v1/collections/${collection}/aggregate`;
    return this.http
      .post(url, body)
      .pipe(nHttpPipe(request, this.snackBar)) as Observable<T[]>;
  }

  post(url: string, request?: Partial<IRequest>): Observable<T | T[]> {
    request = new IRequest(request || {});
    const { body, queryParams, options } = request;
    const { version } = options || {};

    url = `api/v${version || 1}/${url}`;
    return this.http
      .post(url, body, { params: queryParams as HttpParams })
      .pipe(nHttpPipe(request, this.snackBar)) as Observable<T[]>;
  }
}

export const nHttpPipe = (
  request: Partial<IRequest>,
  snackBar: MatSnackBar
) => {
  const duration = 5000;
  const { successMessage, failureMessage } = request.options || {};

  return pipe(
    tap(() => {
      const panelClass = 'fg-green-500';
      const message = successMessage;
      message && snackBar.open(message, '', { duration, panelClass });
    }),
    catchError((error) => {
      const panelClass = 'fg-red-500';
      const message = failureMessage || error.error?.message || error?.error;
      message && snackBar.open(message, '', { duration, panelClass });
      return throwError(() => new Error(error));
    })
  );
};

export const nLookup = (
  from: string,
  field: string
): Record<string, unknown> => {
  return {
    from,
    let: { refIds: `$${field}` },
    pipeline: [
      { $match: { $expr: { $in: ['$_id', { $ifNull: ['$$refIds', []] }] } } },
      { $addFields: { seq: { $indexOfArray: ['$$refIds', '$_id'] } } },
      { $sort: { seq: 1 } },
      { $addFields: { seq: '$$REMOVE' } },
    ],
    as: field,
  };
};
