<div class="app-list bg-blue-grey-900 fg-grey-0" fxLayout="column">
  <!-- HEADER -->
  <div class="header bg-blue-grey-800">
    <div fxLayout="column" fxLayoutAlign="start center">
      <!-- BUTTONS -->
      <div
        fxLayoutAlign="end center"
        style="width: 100%; height: 56px; padding: 0 16px"
      >
        <img
          src="./assets/images/logo/logo.svg"
          style="height: 32px; object-fit: cover; width: 32px"
        />
        <div fxFlex></div>
        <button mat-icon-button>
          <mat-icon [matBadge]="0">notifications</mat-icon>
        </button>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon
            [matBadge]="''"
            matBadgeColor="warn"
            matBadgePosition="below after"
            matBadgeSize="small"
          >
            account_circle
          </mat-icon>
        </button>

        <!-- MENU -->
        <mat-menu #menu="matMenu">
          <div fxLayout="column" style="width: 192px">
            <!-- LOGGED IN AS -->
            <button
              class="mat-caption"
              fxLayout="column"
              fxLayoutAlign="center start"
              mat-menu-item
              style="line-height: 20px"
            >
              <div>目前登入用戶：</div>
              <strong>
                {{
                  (cF.selectedCredential$ | async)?.payload?.username ||
                    (cF.selectedCredential$ | async)?.payload?.email ||
                    (cF.selectedCredential$ | async)?.payload?.mobile
                }}
              </strong>
            </button>
            <mat-divider style="margin-top: 8px"></mat-divider>
            <!-- LOGGED IN AS -->

            <a
              (click)="layout.leftDrawer.close()"
              [routerLink]="['iam/profile']"
              mat-menu-item
            >
              <mat-icon>person</mat-icon>
              <span>個人檔案</span>
            </a>
            <a
              (click)="layout.leftDrawer.close()"
              [routerLink]="['iam/profile']"
              mat-menu-item
            >
              <mat-icon>settings</mat-icon>
              <span>設置</span>
            </a>

            <!-- LOGOUT -->
            <mat-divider style="margin-bottom: 8px"></mat-divider>
            <a [routerLink]="['./logout']" (click)="cF.logout()" mat-menu-item>
              <mat-icon>logout</mat-icon>
              <span>登出</span>
            </a>
            <!-- LOGOUT -->
          </div>
        </mat-menu>
        <!-- MENU -->
      </div>
      <!-- BUTTONS -->

      <!-- IDENTIFIER -->
      <div class="mat-body-2">
        {{
          (cF.selectedCredential$ | async)?.payload?.nickname ||
            (cF.selectedCredential$ | async)?.payload?.name
        }}
      </div>
      <div class="fg-grey-400 mat-caption">
        {{
          (cF.selectedCredential$ | async)?.payload?.username ||
            (cF.selectedCredential$ | async)?.payload?.email ||
            (cF.selectedCredential$ | async)?.payload?.mobile
        }}
      </div>
      <!-- IDENTIFIER -->

      <!-- AVATAR -->
      <div class="avatar" fxFlex fxLayoutAlign="center end">
        <img
          class="bg-blue-grey-900 bd-blue-grey-900"
          src="./assets/images/avatars/avatar-01.svg"
        />
      </div>
      <!-- AVATAR -->
    </div>
  </div>
  <!-- HEADER -->

  <div style="height: 64px"></div>
  <!-- APP LIST -->
  <div fxLayout="column" style="padding: 0 16px">
    <div class="fg-primary-500 mat-subheading-1">
      <strong>{{ '應用模組' | uppercase }}</strong>
    </div>
    <div class="fg-grey-400 mat-caption">為不同業務流程而設的應用模組</div>
  </div>
  <mat-nav-list style="padding: 0">
    <a
      (click)="selectApplication(app.id)"
      [routerLink]="app.router?.routerLink"
      [routerLinkActive]="'active'"
      *ngFor="let app of apps$ | async"
      class="fg-grey-0"
      mat-list-item
    >
      <mat-icon mat-list-avatar>{{ app.icon }}</mat-icon>
      <div mat-line>{{ app.name }}</div>
      <div class="fg-grey-400" mat-line>{{ app.description }}</div>
      <div *ngIf="app.badge" class="bg-red-500 mat-caption chip">
        {{ app.badge }}
      </div>
    </a>
    <a
      [routerLink]="['logout']"
      (click)="cF.logout()"
      class="fg-grey-0"
      mat-list-item
    >
      <mat-icon mat-list-avatar>logout</mat-icon>
      <div mat-line>登出</div>
    </a>
  </mat-nav-list>
  <!-- APP LIST -->
  <div></div>
  <div></div>
</div>
