import { ICellEditorParams } from '@ag-grid-enterprise/all-modules';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'up-boolean-editor',
  templateUrl: './boolean-editor.component.html',
  styleUrls: ['./boolean-editor.component.scss'],
})
export class BooleanEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('checkbox') checkbox!: MatCheckbox;

  params!: ICellEditorParams;
  value!: boolean;
  form!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = params.value;
    this.form = this.formBuilder.group({ value: [this.value] });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.checkbox && this.checkbox.focus());
  }

  getValue(): boolean {
    return !!this.control.value;
  }

  isCancelAfterEnd(): boolean {
    if (this.control.value === null) return true;
    return false;
  }

  get control(): FormControl {
    return this.form.controls.value as FormControl;
  }
}
