import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as LayoutActions from './layout.actions';
import * as LayoutFeature from './layout.reducer';
import * as LayoutSelectors from './layout.selectors';

@Injectable()
export class LayoutFacade {
  shell = { hide: () => this._hideShell(), show: () => this._showShell() };
  toolbar = {
    show: () => this._showToolbar(),
    hide: () => this._hideToolbar(),
    visible$: this.store.pipe(select(LayoutSelectors.getLayoutToolbarVisible)),
  };
  navList = {
    show: () => this._showNavList(),
    hide: () => this._hideNavList(),
    visible$: this.store.pipe(select(LayoutSelectors.getLayoutNavListVisible)),
  };

  constructor(private store: Store) {}

  // PRIVATE HELPERS
  private _hideShell(): void {
    this._hideToolbar();
    this._hideNavList();
  }
  private _showShell(): void {
    this._showToolbar();
    this._showNavList();
  }
  private _showToolbar(): void {
    this.store.dispatch(LayoutActions.showToolbar());
  }
  private _hideToolbar(): void {
    this.store.dispatch(LayoutActions.hideToolbar());
  }
  private _showNavList(): void {
    this.store.dispatch(LayoutActions.showNavList());
  }
  private _hideNavList(): void {
    this.store.dispatch(LayoutActions.hideNavList());
  }
}
