import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from './http/http.module';
import { LayoutModule } from './layout/layout.module';
import { RootStoreModule } from './store/store.module';
import { SocketModule } from './socket/socket.module';
import { MatIconRegistry } from '@angular/material/icon';
import { SplashScreenService } from './services/splash-screen.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NavigationsModule } from './navigations/navigations.module';

export interface IEnvironment {
  endPointHost?: string;
  isCrypted?: boolean;
  production: boolean;
  stage: 'local' | 'dev' | 'uat' | 'stage' | 'prod' | string;
  secret: string;
  stripe?: { pubKey: string };
}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,

    // CUSTOMIZED MODULES
    HttpModule,
    // SocketModule,
    RootStoreModule,
    LayoutModule,
    NavigationsModule,

    // SERVICE WORKER
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
  exports: [LayoutModule, NavigationsModule],
})
export class NAngularModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private splashScreenService: SplashScreenService
  ) {
    this.matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.splashScreenService.hide();
  }

  static forRoot(
    environment: IEnvironment
  ): ModuleWithProviders<NAngularModule> {
    return {
      ngModule: NAngularModule,
      providers: [{ provide: 'ENV', useValue: environment }],
    };
  }
}
