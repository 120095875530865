import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-enterprise/all-modules';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BalanceDialogComponent } from './balance-dialog/balance-dialog.component';

@Component({
  selector: 'up-balance-editor',
  templateUrl: './balance-editor.component.html',
  styleUrls: ['./balance-editor.component.scss'],
})
export class BalanceEditorComponent implements ICellEditorAngularComp {
  public params!: ICellEditorParams;

  constructor(private dialog: MatDialog) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.openBalanceDialog();
  }

  getValue(): any[] {
    return this.params.value;
  }

  isPopup(): boolean {
    return true;
  }

  isCancelAfterEnd(): boolean {
    return true;
  }

  openBalanceDialog(): void {
    const dialog = this.dialog.open(BalanceDialogComponent, {
      panelClass: 'dialog-p0',
    });

    dialog.afterClosed().subscribe(() => {
      this.params.stopEditing();
    });
  }
}
