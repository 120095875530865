import { Component, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { routeAnimations } from '../animations';
import { LayoutFacade } from './+state/layout.facade';

@Component({
  selector: 'up-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [routeAnimations],
})
export class LayoutComponent {
  @ViewChild('leftDrawer') leftDrawer!: MatDrawer;
  @ViewChild('rightDrawer') rightDrawer!: MatDrawer;

  constructor(public lF: LayoutFacade) {}
}
