import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { CredentialsFacade } from '@up/n-angular';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreGuard implements CanActivate {
  constructor(private cF: CredentialsFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.cF.roles$.pipe(
      map((roles) => roles.map((role: any) => role.name)),
      map((roles) => roles.includes('STORE')),
      tap((canActivate) => !canActivate && this.cF.logout()),
      tap((canActivate) => !canActivate && this.router.navigate(['/login']))
    );
  }
}
