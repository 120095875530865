import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { GridComponent } from '../../grid.component';
import { Observable, of } from 'rxjs';
import { HttpService } from '../../../../http/http.service';

@Component({
  selector: 'up-avatar-renderer',
  templateUrl: './avatar-renderer.component.html',
  styleUrls: ['./avatar-renderer.component.scss'],
})
export class AvatarRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;
  public value!: any;
  public avatar$!: Observable<any>;

  constructor(private http: HttpService<any>) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = Array.isArray(params.value)
      ? [...params.value]
      : params.value
      ? [params.value]
      : [];
    this.avatar$ = this.getAvatar$();
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  click(): void {
    const parent: GridComponent = this.params.context.componentParent;
    parent.emitAction({ action: 'AVATAR', data: {} });
  }

  get firstChar(): string {
    const name: string = this.params.data?.name || this.params.data?.title;
    return name?.substr(0, 1).toUpperCase();
  }

  getAvatar$(): Observable<any> {
    const [id] = this.value;
    if (id) return this.http.find.byId({ collection: 'files', id });
    return of(null);
  }
}
