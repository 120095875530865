<mat-form-field fxFlexFill>
  <input
    #input
    [formControl]="control"
    [matDatepicker]="datePicker"
    fxFlex
    matInput
  />
  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>
</mat-form-field>
