import { NgModule } from '@angular/core';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { RouterFacade } from './+state/router.facade';

const { production } = { production: true };

export const storeLogger = (
  reducer: ActionReducer<unknown>
): ActionReducer<unknown> => {
  return (state, action) => {
    console.log('OLD STATE', state, 'ACTION', action);
    return reducer(state, action);
  };
};
export const localStorageSyncReducer = (
  reducer: ActionReducer<unknown>
): ActionReducer<unknown> => {
  return localStorageSync({
    keys: ['credentials', 'navigations'],
    rehydrate: true,
  })(reducer);
};
export const metaReducers: MetaReducer<unknown>[] = [localStorageSyncReducer];

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(
      { router: routerReducer },
      {
        metaReducers: production
          ? metaReducers
          : [storeLogger, ...metaReducers],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({}),
  ],
  providers: [RouterFacade],
})
export class RootStoreModule {}
