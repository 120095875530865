<div fxFlexFill fxLayoutAlign="center center">
  <button
    *ngIf="params.data"
    [color]="'primary'"
    [matMenuTriggerFor]="menu"
    mat-icon-button
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu" yPosition="below">
    <button (click)="click('LOG')" mat-menu-item>
      <mat-icon>bug_report</mat-icon>
      <span>日誌</span>
    </button>
    <button (click)="click('COPY')" mat-menu-item>
      <mat-icon>content_copy</mat-icon>
      <span>複製</span>
    </button>
    <button (click)="click('DELETE')" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span>刪除</span>
    </button>
  </mat-menu>
</div>
