import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { map } from 'rxjs/operators';

import * as NavigationsActions from './navigations.actions';
import { NavigationsEntity } from './navigations.models';
import * as NavigationsFeature from './navigations.reducer';
import * as NavigationsSelectors from './navigations.selectors';

@Injectable()
export class NavigationsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(NavigationsSelectors.getNavigationsLoaded));
  allNavigations$ = this.store.pipe(
    select(NavigationsSelectors.getAllNavigations)
  );
  selectedNavigations$ = this.store.pipe(
    select(NavigationsSelectors.getSelected)
  );
  selectedApplication$ = this.store.pipe(
    select(NavigationsSelectors.getSelectedApplcation)
  );
  selectedNavItem$ = this.store.pipe(
    select(NavigationsSelectors.getSelectedNavItem)
  );

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(navigations?: NavigationsEntity) {
    this.store.dispatch(NavigationsActions.init({ navigations }));
  }

  selectApplication(id: string | number) {
    this.store.dispatch(NavigationsActions.selectApplication({ id }));
  }

  selectNavItem(id: string | number) {
    this.store.dispatch(NavigationsActions.selectNavItem({ id }));
  }
}
