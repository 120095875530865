import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CREDENTIALS_FEATURE_KEY,
  State,
  credentialsAdapter,
} from './credentials.reducer';

// Lookup the 'Credentials' feature state managed by NgRx
export const getCredentialsState = createFeatureSelector<State>(
  CREDENTIALS_FEATURE_KEY
);

const { selectAll, selectEntities } = credentialsAdapter.getSelectors();

export const getCredentialsLoaded = createSelector(
  getCredentialsState,
  (state: State) => state.loaded
);

export const getCredentialsError = createSelector(
  getCredentialsState,
  (state: State) => state.error
);

export const getAllCredentials = createSelector(
  getCredentialsState,
  (state: State) => selectAll(state)
);

export const getCredentialsEntities = createSelector(
  getCredentialsState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getCredentialsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getCredentialsEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getCredentialsLoading = createSelector(
  getCredentialsState,
  (state: State) => state.loading
);
