import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoButtonRendererComponent } from './info-button-renderer/info-button-renderer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AvatarRendererComponent } from './avatar-renderer/avatar-renderer.component';
import { ReferenceRendererComponent } from './reference-renderer/reference-renderer.component';
import { FilesRendererComponent } from './files-renderer/files-renderer.component';
import { MatRippleModule } from '@angular/material/core';
import { MoreButtonRendererComponent } from './more-button-renderer/more-button-renderer.component';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    InfoButtonRendererComponent,
    AvatarRendererComponent,
    ReferenceRendererComponent,
    FilesRendererComponent,
    MoreButtonRendererComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,

    // MATERIAL
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    MatSnackBarModule,

    // MATERIAL CDK
    ClipboardModule,
  ],
})
export class RenderersModule {}

export const renderers = {
  avatarRenderer: AvatarRendererComponent,
  filesRenderer: FilesRendererComponent,
  infoButtonRenderer: InfoButtonRendererComponent,
  moreButtonRenderer: MoreButtonRendererComponent,
  referenceRenderer: ReferenceRendererComponent,
};
