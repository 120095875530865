import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-enterprise/all-modules';
import { GridComponent } from '../../grid.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'up-refresh-button-header',
  templateUrl: './refresh-button-header.component.html',
  styleUrls: ['./refresh-button-header.component.scss'],
})
export class RefreshButtonHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams): boolean {
    this.agInit(params);
    return true;
  }

  click(): void {
    const parent: GridComponent = this.params.context.componentParent;

    if (parent.isServerSide)
      this.params.api.refreshServerSideStore({ purge: true });
    else parent.rowData$ = parent.rowData$.pipe(take(1));

    parent.emitAction({ action: 'REFRESH', data: {} });
  }
}
