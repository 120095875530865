import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-enterprise/all-modules';
import { IFilterDef } from '@ag-grid-enterprise/all-modules';

const numberValueGetter = (params: ValueGetterParams) => {
  if (!params.data) return 0;
  const float = Math.round(params.data[params.colDef.field || ''] * 100) / 100;
  return float;
};
const integerValueFormatter = (params: ValueFormatterParams) => {
  const value = +params.value;
  if (Number.isNaN(value)) return '';

  if (Math.round(value * 100) / 100 === 0) return `0`;
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
const decimalValueFormatter = (params: ValueFormatterParams) => {
  const value = +params.value;
  if (Number.isNaN(value)) return '';

  if (Math.round(value * 100) / 100 == 0) return `0.00`;
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const cellClassRules = {
  'fg-red-500': (params: any) => Math.round(params.value * 100) / 100 < 0,
  'fg-green-500': (params: any) => Math.round(params.value * 100) / 100 > 0,
};

const filterParams: { filters: IFilterDef[] } = {
  filters: [
    { filter: 'agNumberColumnFilter', filterParams: { newRowsAction: 'keep' } },
    { filter: 'agSetColumnFilter', filterParams: { newRowsAction: 'keep' } },
  ],
};

export const numberColumn: ColDef = {
  cellClassRules,
  cellEditor: 'numberEditor',
  cellStyle: { 'text-align': 'right' },
  filterParams,
  headerClass: 'ag-right-aligned-header',
  initialWidth: 128,
  // valueGetter: numberValueGetter,
  width: 128,
};

export const integerColumn: ColDef = {
  ...numberColumn,
  valueFormatter: integerValueFormatter,
};

export const decimalColumn: ColDef = {
  ...numberColumn,
  valueFormatter: decimalValueFormatter,
};
