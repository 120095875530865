import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { IRequest } from '../http.module';
import { nHttpPipe } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class InsertService<T> {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  // INSERT ONE
  one(request: Partial<IRequest>): Observable<T> {
    const { collection, body, queryParams, options } = new IRequest(request);
    const { version, skipConfirm } = options || {};

    const params = (queryParams || {}) as HttpParams;
    const url = `api/v${version || 1}/collections/${collection}/one`;

    return this.http
      .post(url, body, { params })
      .pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // INSERT MANY
  many(request: Partial<IRequest>): Observable<T[]> {
    const { collection, body, queryParams, options } = new IRequest(request);
    const { version, skipConfirm } = options || {};

    const params = (queryParams || {}) as HttpParams;
    const url = `api/v${version || 1}/collections/${collection}/many`;

    return this.http
      .post(url, body, { params })
      .pipe(nHttpPipe(request, this.snackBar)) as Observable<T[]>;
  }
}
