<ag-grid-angular
  #agGrid
  (gridReady)="gridReady($event)"
  (rowSelected)="rowSelected($event)"
  [context]="context"
  [gridOptions]="gridOptions"
  [modules]="modules"
  [rowData]="rowData$ | async"
  [rowModelType]="isServerSide ? 'serverSide' : 'clientSide'"
  class="ag-theme-material"
  style="height: 100%; width: 100%"
></ag-grid-angular>
