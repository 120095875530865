import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { IRequest } from '../http.module';
import { nHttpPipe } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class FindService<T> {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  // FIND BY ID
  byId(request: Partial<IRequest>): Observable<T> {
    const { collection, id, queryParams, options, ...req } = new IRequest(
      request
    );
    const { rawQueryParams } = req;
    const { byPost, version } = options || {};

    const params = byPost === false ? ((queryParams || {}) as HttpParams): {};
    const body = { queryParams: rawQueryParams };
    const url =
      byPost === false
        ? `api/v${version || 1}/collections/${collection}/id/${id}`
        : `api/v${version || 1}/collections/${collection}/find/id/${id}`;

    const action =
      byPost === false
        ? this.http.get(url, { params })
        : this.http.post(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // FIND ONE
  one(request: Partial<IRequest>): Observable<T> {
    const { collection, queryParams, options, ...req } = new IRequest(request);
    const { rawQueryParams } = req;
    const { byPost, version } = options || {};

    const params = byPost === false ? ((queryParams || {}) as HttpParams): {};
    const body = { queryParams: rawQueryParams };
    const url =
      byPost === false
        ? `api/v${version || 1}/collections/${collection}/one`
        : `api/v${version || 1}/collections/${collection}/find/one`;

    const action =
      byPost === false
        ? this.http.get(url, { params })
        : this.http.post(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // FIND OWNED BY ME
  ownedByMe(request: Partial<IRequest>): Observable<T> {
    const { collection, queryParams, options, ...req } = new IRequest(request);
    const { rawQueryParams } = req;
    const { byPost, version } = options || {};

    const params = byPost === false ? ((queryParams || {}) as HttpParams): {};
    const body = { queryParams: rawQueryParams };
    const url =
      byPost === false
        ? `api/v${version || 1}/collections/${collection}/me`
        : `api/v${version || 1}/collections/${collection}/find/me`;

    const action =
      byPost === false
        ? this.http.get(url, { params })
        : this.http.post(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }

  // FIND
  by(request: Partial<IRequest>): Observable<T> {
    const { collection, queryParams, options, ...req } = new IRequest(request);
    const { rawQueryParams } = req;
    const { byPost, version } = options || {};

    const params = byPost === false ? ((queryParams || {}) as HttpParams): {};
    const body = { queryParams: rawQueryParams };
    const url =
      byPost === false
        ? `api/v${version || 1}/collections/${collection}/`
        : `api/v${version || 1}/collections/${collection}/find`;

    const action =
      byPost === false
        ? this.http.get(url, { params })
        : this.http.post(url, body, { params });
    return action.pipe(nHttpPipe(request, this.snackBar)) as Observable<T>;
  }
}
