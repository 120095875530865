import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './grid.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromGrid from './+state/grid.reducer';
import { GridEffects } from './+state/grid.effects';
import { GridFacade } from './+state/grid.facade';
import { RenderersModule } from './renderers/renderers.module';
import { EditorsModule } from './editors/editors.module';
import { HeadersModule } from './headers/headers.module';
import { FilesDialogModule } from '../files-dialog/files-dialog.module';
import { LogsDialogModule } from '../logs-dialog/logs-dialog.module';

// yarn add @ag-grid-community/all-modules @ag-grid-community/angular @ag-grid-enterprise/all-modules @ngrx/component-store @ngrx/effects @ngrx/entity @ngrx/router-store @ngrx/store moment moment-timezone pluralize @tinymce/tinymce-angular
// yarn add -D @types/pluralize

@NgModule({
  declarations: [GridComponent],
  imports: [
    CommonModule,
    // StoreModule.forFeature(fromGrid.GRID_FEATURE_KEY, fromGrid.reducer),
    // EffectsModule.forFeature([GridEffects]),

    // AG GRID MODULES
    HeadersModule,
    RenderersModule,
    EditorsModule,

    // DEPENDENCY MODULES
    FilesDialogModule,
    LogsDialogModule,

    // 3RD PARTY
    AgGridModule.withComponents([]),
  ],
  providers: [GridFacade],
  exports: [GridComponent],
})
export class GridModule {}
