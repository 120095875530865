<div fxFlexFill fxLayout="column">
  <!-- TOOLBAR -->
  <mat-toolbar
    class="bg-primary-50 fg-primary-700 mat-elevation-z2"
    fxLayout="row"
  >
    <button mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <div fxFlex></div>
    <button mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
  </mat-toolbar>
  <!-- TOOLBAR -->

  <!-- TABS -->
  <mat-tab-group
    mat-stretch-tabs
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="selectedTabChange($event)"
  >
    <mat-tab [label]="'已選（' + selectedFileIds.length + '）'"></mat-tab>
    <mat-tab label="所有檔案"></mat-tab>
  </mat-tab-group>
  <!-- TABS -->

  <!-- CONTENT -->
  <div fxFlex fxLayout="column" style="overflow-y: auto; padding-bottom: 40px">
    <div
      *ngFor="let file of files$ | async"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <!-- ICON -->
      <div
        (click)="onFileClicked(file._id)"
        [matBadgeColor]="'accent'"
        [matBadgePosition]="'below after'"
        [matBadge]="getFileIndex(file._id) ? getFileIndex(file._id) : undefined"
        style="margin: 16px"
      >
        <div
          [ngSwitch]="getFileType(file)"
          class="bd-primary-500"
          fxLayoutAlign="center center"
          matRipple
          style="
            border-radius: 4px;
            border: 1px solid;
            cursor: pointer;
            height: 56px;
            width: 56px;
          "
        >
          <img
            *ngSwitchCase="'image'"
            [src]="file.formats?.thumbnail?.url || file.urls?.xs"
          />
          <div *ngSwitchCase="'pdf'" class="fg-accent-500">
            <mat-icon>picture_as_pdf</mat-icon>
          </div>
          <div *ngSwitchDefault class="fg-accent-500">
            <mat-icon>insert_drive_file</mat-icon>
          </div>
        </div>
      </div>
      <!-- ICON -->

      <!-- DESCRIPTION -->
      <div fxFlex fxLayout="column" style="overflow-x: hidden">
        <div class="mat-subheading-2 text-ellipsis">
          <a
            *ngIf="file.formats"
            [href]="
              file.formats.large?.url ||
              file.formats.medium?.url ||
              file.formats.small?.url
            "
            target="_blank"
          >
            {{ file.name }}
          </a>
          <a
            *ngIf="file.urls"
            [href]="file.urls.xl || file.urls.pdf"
            target="_blank"
          >
            {{ file.name }}
          </a>
        </div>
        <div class="mat-caption fg-grey-500 text-ellipsis">
          <span *ngIf="(file.size || 0) / 1024 / 1024 < 1">
            {{ (file.size || 0) / 1024 | currency: '':'':'1.2-2' }} KB,
          </span>
          <span *ngIf="(file.size || 0) / 1024 / 1024 > 1">
            {{ (file.size || 0) / 1024 / 1024 | currency: '':'':'1.2-2' }} MB,
          </span>
          {{ file.createdAt | date: 'YYYY-MM-dd HH:mm:ss' }}
        </div>
      </div>
      <!-- DESCRIPTION -->

      <!-- BUTTON -->
      <button class="fg-primary-700" mat-icon-button style="margin-right: 8px">
        <mat-icon>more_vert</mat-icon>
      </button>
      <!-- BUTTON -->
    </div>
  </div>
  <!-- CONTENT -->

  <!-- ACTIONS -->
  <mat-toolbar fxLayoutAlign="end center">
    <button [mat-dialog-close] mat-button style="margin-right: 8px">
      取消
    </button>
    <button
      [color]="'accent'"
      [disabled]="isUploading"
      [mat-dialog-close]="selectedFileIds"
      mat-raised-button
    >
      <div *ngIf="!isUploading">確定</div>
      <mat-spinner
        *ngIf="isUploading"
        [diameter]="24"
        mode="indeterminate"
      ></mat-spinner>
    </button>
  </mat-toolbar>
  <!-- ACTIONS -->

  <!-- FAB -->
  <button
    (click)="upload.value = ''; upload.click()"
    [color]="'primary'"
    [ngStyle.gt-sm]="{ bottom: '32px' }"
    [ngStyle.lt-md]="{ bottom: '28px' }"
    [disabled]="isUploading"
    mat-fab
  >
    <mat-icon *ngIf="!isUploading">add</mat-icon>
    <mat-spinner
      *ngIf="isUploading"
      [diameter]="24"
      mode="indeterminate"
    ></mat-spinner>
    <input
      #upload
      (change)="onFileSelected($event)"
      hidden
      multiple
      type="file"
    />
  </button>
  <!-- FAB -->
</div>
