export * from './lib/n-angular.module';

// MODULES
export * from './lib/layout/layout.module';
export * from './lib/modules/iam/login/login.module';
export * from './lib/modules/iam/iam.module';

// COMPONENTS
export * from './lib/widgets/grid/grid.component';

// WIDGETS
export * from './lib/widgets/grid/grid.module';

// SERVICES
export * from './lib/http/http.service';
export * from './lib/socket/socket.service';
export * from './lib/credentials/credentials.service';
export * from './lib/services/splash-screen.service';

// FACADES
export * from './lib/store/+state/router.facade';
export * from './lib/credentials/+state/credentials.facade';
export * from './lib/navigations/+state/navigations.facade';
export * from './lib/layout/+state/layout.facade';

// MODELS
export * from './lib/credentials/+state/credentials.models';
export * from './lib/navigations/+state/navigations.models';

// GUARDS
export * from './lib/credentials/credentials.guard';
export * from './lib/credentials/login-redirect.guard';

// ANIMATIONS
export * from './lib/animations';

// NAVIGATIONS
export * from './lib/navigations/navigations';


// yarn add @ag-grid-community/all-modules @ag-grid-community/angular @ag-grid-enterprise/all-modules @angular/cdk @angular/flex-layout @angular/localize @angular/material @angular/material-moment-adapter @angular/service-worker @auth0/angular-jwt @ironcorelabs/ironweb @ngrx/component-store @ngrx/effects @ngrx/entity @ngrx/router-store @ngrx/store @ngrx/store-devtools @progress/kendo-angular-buttons @progress/kendo-angular-common @progress/kendo-angular-dialog @progress/kendo-angular-dropdowns @progress/kendo-angular-editor @progress/kendo-angular-inputs @progress/kendo-angular-intl @progress/kendo-angular-l10n @progress/kendo-angular-popup @progress/kendo-angular-toolbar @progress/kendo-angular-treeview @progress/kendo-drawing @progress/kendo-licensing @progress/kendo-theme-material country-flag-icons crypto-js moment moment-timezone ngx-device-detector ngx-socket-io ngrx-store-localstorage realm-web pluralize tweetnacl
// yarn add -D @types/country-flag-icons @types/crypto-js @types/pluralize
