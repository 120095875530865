import { NgModule, Provider } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EncryptionService } from './interceptors/encryption.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { CredentialsFacade } from '../credentials/+state/credentials.facade';
import { CredentialsModule } from '../credentials/credentials.module';
import { map, take } from 'rxjs/operators';
import { Base64 } from 'js-base64';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom } from 'rxjs';

const jwtOptionsProvider: Provider = {
  provide: JWT_OPTIONS,
  useFactory: (cF: CredentialsFacade) => ({
    throwNoTokenError: false,
    tokenGetter: async () =>
      await lastValueFrom(
        cF.selectedCredential$.pipe(
          take(1),
          map((credential) => credential?.accessToken)
        )
      ),
  }),
  deps: [CredentialsFacade],
};

export class IRequest {
  collection?: string;
  id?: string;
  queryParams?: {
    match?: Record<string, unknown>;
    lookups?: Record<string, unknown>[];
    skip?: number;
    limit?: number;
    sort?: { [key: string]: 1 | -1 };
    project?: { [key: string]: 1 | -1 | string };
    checksum?: boolean;
    preview?: boolean;
    returnOriginal?: boolean;
  } = {};
  body?: any;
  options?: {
    byPost?: boolean;
    failureMessage?: string;
    skipConfirm?: boolean;
    successMessage?: string;
    version?: number;
  };
  rawQueryParams?: IRequest['queryParams'];

  constructor(request: Partial<IRequest>) {
    this.rawQueryParams = { ...request.queryParams };
    this._serialize(request);
  }

  private _serialize(request: Partial<IRequest>): void {
    this.collection = request.collection;
    this.id = request.id;
    this.queryParams = this.encode(request.queryParams);
    this.body = request.body;
    this.options = request.options || {};
  }

  public decode(value: Record<string, string>): Record<string, unknown> {
    const output: any = {};

    for (const key in value) {
      if (key === 'preview' || key === 'checksum' || key === 'returnOriginal')
        output[key] = value[key] === 'true';
      else if (key === 'skip' || key === 'limit') output[key] = +value[key];
      else output[key] = JSON.parse(Base64.decode(value[key]));
    }

    return output;
  }
  public encode(value: Record<string, string> | any): Record<string, string> {
    for (const key in value)
      value[key] = Base64.encode(JSON.stringify(value[key]));

    return value;
  }
}

// yarn add @auth0/angular-jwt

@NgModule({
  imports: [
    HttpClientModule,
    CredentialsModule,
    JwtModule.forRoot({ jwtOptionsProvider }),

    // MATERIAL
    MatSnackBarModule,
  ],
  providers: [
    DeviceDetectorService,
    { provide: HTTP_INTERCEPTORS, useClass: EncryptionService, multi: true },
  ],
})
export class HttpModule {}
