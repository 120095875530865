import {
  trigger,
  animate,
  transition,
  style,
  group,
  query,
} from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
  transition('* => *', [
    group([
      query(
        ':leave',
        [
          style({
            transform: 'translateX(0%)',
            opacity: 1,
          }),
          animate(
            '300ms cubic-bezier(0.4, 0.0, 1, 1)',
            style({
              transform: 'translateX(-25%)',
              opacity: 0,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({
            transform: 'translateX(25%)',
            opacity: 0,
          }),
          animate(
            '300ms cubic-bezier(0.0, 0.0, 0.2, 1)',
            style({
              transform: 'translateX(0%)',
              opacity: 1,
            })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);
