import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsFacade } from '../../../credentials/+state/credentials.facade';

import { CredentialsService } from '../../../credentials/credentials.service';
import { LayoutFacade } from '../../../layout/+state/layout.facade';
import { RouterFacade } from '../../../store/+state/router.facade';

@Component({
  selector: 'up-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('identifierInput') identifierInput!: ElementRef;

  title = '';
  description = '';
  caption = '';
  isPasswordVisible = false;
  isLoading = false;

  locale$ = this.rF.routeParam$('locale');

  constructor(
    public cS: CredentialsService,
    public cF: CredentialsFacade,
    private lF: LayoutFacade,
    private rF: RouterFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.lF.shell.hide();
    this.cS.otpForm.valueChanges.subscribe((values) =>
      this.cS.credentialForm.patchValue({ secret: values.join('') })
    );
    this.cF.loading$.subscribe((loading) => {
      if (loading) {
        this.cS.credentialForm.disable();
        this.cS.mobileForm.disable();
        this.cS.otpForm.disable();
      } else {
        setTimeout(() => {
          this.cS.credentialForm.enable();
          this.cS.mobileForm.enable();
          this.cS.otpForm.enable();
          this.cS.otpForm.reset();
        }, 300);
      }
    });
    this.cF.selectedCredential$.subscribe(
      (credential) =>
        credential &&
        this.router.navigate(
          [credential?.payload?.continueUrl || '../redirect'],
          { relativeTo: this.route }
        )
    );
    this.route.data.subscribe(({ title, description, caption }) => {
      this.title = title;
      this.description = description;
      this.caption = caption;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.identifierInput.nativeElement.focus());
  }

  sendVerificationCode(): void {
    this.cS.sendVerificationCode(this.cS.credentialForm.value).subscribe(() => {
      this.cS.otpForm.reset();
      (document.querySelector(`#otp-0`) as any)?.focus();
    });
  }
  onOTPInput($event: any, control: AbstractControl, index: number): void {
    if ($event.inputType !== 'deleteContentBackward')
      (document.querySelector(`#otp-${index + 1}`) as any)?.focus();
    if (this.cS.otpForm.valid) {
      const { areaCode, number } = this.cS.mobileForm.value;
      this.cS.credentialForm.patchValue({
        identifier: `+${areaCode}-${number}`,
      });
      this.login();
    }
  }
  onBackspaceKeydown(control: AbstractControl, index: number) {
    if (control.value.length === 0)
      (document.querySelector(`#otp-${index - 1}`) as any)?.focus();
    else control.setValue('');
  }

  selectStategy(
    strategy: 'MOBILE+OTP' | 'USERNAME+PASSWORDS.LOGIN' | 'CREDENTIALS'
  ): void {
    if (strategy !== 'MOBILE+OTP') {
      this.cS.mobileForm.patchValue({ number: '' });
      this.cS.otpForm.reset();
    }
    this.cS.credentialForm.setValue({
      strategy,
      identifier: null,
      secret: null,
    });
    setTimeout(() => this.identifierInput.nativeElement.focus());
  }
  login(): void {
    if (this.cS.credentialForm.valid)
      this.cF.authenticate(this.cS.credentialForm.value);
  }
}
