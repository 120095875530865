import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefreshButtonHeaderComponent } from './refresh-button-header/refresh-button-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddButtonHeaderComponent } from './add-button-header/add-button-header.component';
import { NewButtonHeaderComponent } from './new-button-header/new-button-header.component';

@NgModule({
  declarations: [
    RefreshButtonHeaderComponent,
    AddButtonHeaderComponent,
    NewButtonHeaderComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,

    // MATERIAL
    MatButtonModule,
    MatIconModule,
  ],
})
export class HeadersModule {}

export const headers = {
  addButtonHeader: AddButtonHeaderComponent,
  newButtonHeader: NewButtonHeaderComponent,
  refreshButtonHeader: RefreshButtonHeaderComponent,
};
