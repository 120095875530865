import { ColDef } from '@ag-grid-enterprise/all-modules';

export const filesColumn: ColDef = {
  cellEditor: 'filesEditor',
  cellRenderer: 'filesRenderer',
  cellStyle: { padding: '0 8px' },
  field: 'files',
  headerName: '檔案',
  initialWidth: 172,
  maxWidth: 172,
  minWidth: 172,
  width: 172,
};
