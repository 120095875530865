import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GRID_FEATURE_KEY, State, gridAdapter } from './grid.reducer';

// Lookup the 'Grid' feature state managed by NgRx
export const getGridState = createFeatureSelector<State>(GRID_FEATURE_KEY);

const { selectAll, selectEntities } = gridAdapter.getSelectors();

export const getGridLoaded = createSelector(
  getGridState,
  (state: State) => state.loaded
);

export const getGridError = createSelector(
  getGridState,
  (state: State) => state.error
);

export const getAllGrid = createSelector(getGridState, (state: State) =>
  selectAll(state)
);

export const getGridEntities = createSelector(getGridState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getGridState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getGridEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
