import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store, Action } from '@ngrx/store';
import { exhaustMap, lastValueFrom, of } from 'rxjs';
import { HttpService } from '../../http/http.service';
import { RouterFacade } from '../../store/+state/router.facade';

import * as CredentialsActions from './credentials.actions';
import { Credential } from './credentials.models';
import * as CredentialsFeature from './credentials.reducer';
import * as CredentialsSelectors from './credentials.selectors';

@Injectable()
export class CredentialsFacade {
  loading$ = this.store.pipe(
    select(CredentialsSelectors.getCredentialsLoading)
  );
  loaded$ = this.store.pipe(select(CredentialsSelectors.getCredentialsLoaded));
  credentials$ = this.store.pipe(
    select(CredentialsSelectors.getAllCredentials)
  );
  selectedCredential$ = this.store.pipe(
    select(CredentialsSelectors.getSelected)
  );
  roles$ = this.selectedCredential$.pipe(
    exhaustMap((credential: any) =>
      credential?.payload
        ? this.http.find.by({
            collection: 'roles',
            queryParams: {
              match: { _id: { $in: credential?.payload?.roles } },
            },
          })
        : of([])
    )
  );

  constructor(
    private store: Store,
    private router: Router,
    private rF: RouterFacade,
    private http: HttpService<any>
  ) {}

  init() {
    this.store.dispatch(CredentialsActions.init());
  }

  // RELOAD SELECTED CREDENTIAL PAYLOAD
  reloadSelectedCredentialPayload(payload?: any) {
    this.store.dispatch(
      CredentialsActions.reloadSelectedCredentialPayload({ payload })
    );
  }

  // AUTHENTICATE
  authenticate(credential: Credential): void {
    this.store.dispatch(CredentialsActions.authenticate({ credential }));
  }
  removeCredential(id: string | number): void {
    this.store.dispatch(CredentialsActions.removeCredential({ id }));
  }
  logout(): void {
    this.store.dispatch(CredentialsActions.logout());
    lastValueFrom(this.rF.url$).then((url) =>
      this.router.navigate([url, 'logout'])
    );
  }
}
