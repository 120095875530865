import { ColDef } from '@ag-grid-enterprise/all-modules';

export const refreshButtonColumn: ColDef = {
  cellRenderer: 'moreButtonRenderer',
  cellStyle: { padding: 0 },
  editable: false,
  filter: false,
  headerClass: 'p0',
  headerComponent: 'refreshButtonHeader',
  initialWidth: 56,
  maxWidth: 56,
  minWidth: 56,
  pinned: 'right',
  resizable: false,
  sortable: false,
  suppressCellFlash: true,
  suppressMenu: true,
  suppressMovable: true,
  suppressNavigable: true,
  width: 56,
};
