import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesDialogComponent } from './files-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [FilesDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,

    // MATERIAL
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatTabsModule,
    MatToolbarModule,
    MatDialogModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
  ],
})
export class FilesDialogModule {}
