import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { GridComponent } from '../../grid.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'up-files-renderer',
  templateUrl: './files-renderer.component.html',
  styleUrls: ['./files-renderer.component.scss'],
})
export class FilesRendererComponent implements AgRendererComponent {
  private cellRendererParams!: { entity?: string };

  public params!: ICellRendererParams;
  public files$!: Observable<any[]>;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = params.colDef?.cellRendererParams;
    this.files$ = this.serialize(params.value);
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  serialize(values: unknown[]): Observable<any> {
    if (!values) return of([]);
    values = Array.isArray(values) ? values : [values];
    const entity = this.cellRendererParams?.entity || 'files';

    return of(values);
  }

  click(): void {
    const parent: GridComponent = this.params.context.componentParent;
    parent.emitAction({ action: 'FILE', data: {} });
  }

  getFileType(file: any): string {
    if (
      file?.mime?.split('/')[0] === 'image' ||
      file?.mimetype?.split('/')[0] === 'image'
    )
      return 'image';
    else if (
      file?.mime?.split('/')[1] === 'pdf' ||
      file?.mimetype?.split('/')[1] === 'pdf'
    )
      return 'pdf';

    return 'image';
  }
}
