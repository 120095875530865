import {
  Component,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-enterprise/all-modules';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';
import { HttpService } from '@up/n-angular';

@Component({
  selector: 'up-email-unique-editor',
  templateUrl: './email-unique-editor.component.html',
  styleUrls: ['./email-unique-editor.component.scss'],
})
export class EmailUniqueEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;

  params!: ICellEditorParams;
  value!: string;
  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpService<any>
  ) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = !params.value ? '' : params.value;
    this.form = this.formBuilder.group({
      value: [this.value, [Validators.email]],
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input && this.input.element.nativeElement.focus();
      this.input && this.input.element.nativeElement.select();
    });
  }

  getValue(): string | undefined {
    if (this.control.value === null) return undefined;
    return `${this.control.value}`;
  }

  isCancelAfterEnd(): boolean {
    if (this.value === this.control.value) return true;

    // VALIDATION
    if (this.form.invalid) {
      this.snackBar.open('電郵地址格式不正確', '', {
        panelClass: 'fg-red-500',
      });
      return true;
    }

    return false;
  }

  get control(): FormControl {
    return this.form.controls.value as FormControl;
  }
}
