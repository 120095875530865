<div class="nav-list bg-blue-grey-900 fg-grey-0" fxLayout="column">
  <!-- APPS BUTTON -->
  <div
    [ngStyle.gt-sm]="{ height: '64px' }"
    [ngStyle.lt-md]="{ height: '56px' }"
    fxLayoutAlign="start center"
  >
    <div
      [fxFlex.gt-sm]="'64px'"
      [fxFlex.lt-md]="'56px'"
      fxLayoutAlign="center center"
    >
      <button (click)="layout.leftDrawer.open()" mat-icon-button>
        <mat-icon>apps</mat-icon>
      </button>
    </div>
  </div>
  <!-- APPS BUTTON -->

  <!-- APP ICON -->
  <div
    [ngStyle.gt-sm]="{ height: '64px' }"
    [ngStyle.lt-md]="{ height: '56px' }"
    class="bg-primary-50 fg-primary-700"
    fxLayoutAlign="start center"
  >
    <div
      [fxFlex.gt-sm]="'64px'"
      [fxFlex.lt-md]="'56px'"
      fxLayoutAlign="center center"
    >
      <mat-icon class="app-icon">{{ (app$ | async)?.icon }}</mat-icon>
    </div>
    <div class="mat-title text-ellipsis" fxFlex style="padding-right: 16px">
      {{ (app$ | async)?.name | uppercase }}
    </div>
  </div>
  <!-- APP ICON -->

  <!-- NAV LIST -->
  <div fxFlex style="overflow-x: hidden; overflow-y: auto">
    <mat-nav-list>
      <a
        (click)="selectNavItem(nav)"
        [routerLink]="nav.router?.routerLink"
        [routerLinkActive]="'active'"
        *ngFor="let nav of navs$ | async"
        class="fg-grey-0"
        mat-list-item
      >
        <mat-icon [ngStyle.lt-md]="{ 'margin-left': '-4px' }" mat-list-icon>
          {{ nav.icon }}
        </mat-icon>
        <div mat-line>{{ nav.name }}</div>
        <div *ngIf="nav.badge" class="bg-red-500 mat-caption chip">
          {{ nav.badge }}
        </div>
      </a>
    </mat-nav-list>
  </div>
  <!-- NAV LIST -->

  <!-- NAV BUTTONS -->
  <div
    [ngStyle.gt-sm]="{ height: '64px' }"
    [ngStyle.lt-md]="{ height: '56px' }"
    class="bd-grey-300"
    style="border-top: 1px solid"
  >
    <div
      [fxFlex.gt-sm]="'64px'"
      [fxFlex.lt-md]="'56px'"
      fxLayoutAlign="center center"
    >
      <button (click)="navBack()" mat-icon-button>
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <div fxFlex></div>
    <div
      [fxFlex.gt-sm]="'64px'"
      [fxFlex.lt-md]="'56px'"
      fxLayoutAlign="center center"
    >
      <button (click)="navForward()" mat-icon-button>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
  <!-- NAV BUTTONS -->
</div>
