import { createAction, props } from '@ngrx/store';
import { NavigationsEntity } from './navigations.models';

export const init = createAction(
  '[Navigations Page] Init',
  props<{ navigations?: NavigationsEntity }>()
);

export const loadNavigationsSuccess = createAction(
  '[Navigations/API] Load Navigations Success',
  props<{ navigations: NavigationsEntity[] }>()
);

export const loadNavigationsFailure = createAction(
  '[Navigations/API] Load Navigations Failure',
  props<{ error: any }>()
);

export const selectNavigation = createAction(
  '[Navigations] Select Navigation',
  props<{ id: string | number }>()
);

export const selectApplication = createAction(
  '[Navigations] Select Application',
  props<{ id: string | number }>()
);
export const selectNavItem = createAction(
  '[Navigations] Select Nav Item',
  props<{ id: string | number }>()
);
